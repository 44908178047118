import { assertUnreachable } from "src/utils/types";
import { Trans } from "@lingui/macro";
import { Text } from "@gocardless/flux-react";

import { FundCategoryType } from "../components/constants";

export const FundCategoryTooltipCopy: React.FC<{
  fundCategoryType: FundCategoryType;
  isNegative?: boolean;
}> = ({ fundCategoryType, isNegative = false }) => {
  switch (fundCategoryType) {
    case FundCategoryType.COLLECTED:
      return (
        <>
          <Text>
            <Trans id="home.collected-tooltip">
              These funds have been successfully collected from your customers
              and are being processed for payout to you.
            </Trans>
          </Text>
          {isNegative ? (
            <>
              <br />
              <br />
              <Trans id="home.negative-balance.info">
                A negative balance can be due to refunds, disputed payments or
                bank errors. We&apos;ll be able to pay out to you when the
                balance is positive again.
              </Trans>
            </>
          ) : null}
        </>
      );
    case FundCategoryType.PAYOUT:
      return (
        <Trans id="home.payout-tooltip">
          These amounts have had their fees deducted. For amounts that are due,
          the value shown is an estimate until the exchange rate has been
          applied on the day of the payout.
        </Trans>
      );
    case FundCategoryType.PENDING:
      return (
        <Trans id="home.pending-tooltip">
          These funds are currently being collected from your customers. The
          amount shown includes any deductions of fees.
        </Trans>
      );
    default:
      assertUnreachable(fundCategoryType as never);
  }
};
