export interface ActionComponentProps {
  id: SuggestedActionType;
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
}

export enum SuggestedActionType {
  LeaveFeedback = "LeaveFeedback",
  TwoFactorAuth = "TwoFactorAuth",
  TrySubscriptionTemplates = "TrySubscriptionTemplates",
  TryInstalments = "TryInstalments",
  RetryFailedPayments = "RetryFailedPayments",
  AddMoreCustomers = "AddMoreCustomers",
  AddCustomersInBulk = "AddCustomersInBulk",
  TryDDPayments = "TryDDPayments",
  TryIBPPayments = "TryIBPPayments",
  CreateMorePayments = "CreateMorePayments",
  SwitchCustomersBenefits = "SwitchCustomersBenefits",
}

export const STORAGE_KEY = "gc.suggested_actions";
