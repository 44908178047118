import {
  AlignItems,
  Box,
  Color,
  H3,
  JustifyContent,
  TypePreset,
  ColorPreset,
} from "@gocardless/flux-react";

interface DialogBodyWrapperProps {
  children: React.ReactNode;
  image: React.ReactNode;
  title: React.ReactNode;
  bg: Color | ColorPreset;
}
export const DialogBodyWrapper = ({
  children,
  image,
  title,
  bg,
}: DialogBodyWrapperProps) => (
  <>
    <Box
      layout="flex"
      bg={bg}
      gutterV={2}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      spaceBelow={2}
      borderRadius={1}
    >
      {image}
    </Box>

    <H3 spaceBelow={1} preset={TypePreset.Heading_03}>
      {title}
    </H3>
    {children}
  </>
);
