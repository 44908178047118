export const getMostRecentDate = (dateTimeStrings: Array<string>) => {
  if (!dateTimeStrings.length) {
    return;
  }

  const timestamps = dateTimeStrings.map((dateTimeString) =>
    new Date(dateTimeString).getTime()
  );

  const maxTimestamp = Math.max(...timestamps);

  const mostRecentDate = new Date(maxTimestamp).toISOString();

  return mostRecentDate;
};

export const LEARN_MORE_BASE_URL =
  "https://hub.gocardless.com/s/article/Dashboard-reporting";
