import {
  Endpoint,
  getEndpointURL,
} from "@gocardless/api/dashboard/common/endpoints";
import {
  CurrencyExchangeRateListResponseBody,
  CurrencyExchangeRateResource,
} from "@gocardless/api/dashboard/types";
import api from "@gocardless/api/utils/api";
import { useState, useEffect } from "react";

const useFetchCurrencyExchangeRateList = ({
  source,
  target,
}: {
  source?: null | string;
  target?: null | string;
}) => {
  const [currencyExchangeRateList, setCurrencyExchangeRateList] =
    useState<CurrencyExchangeRateResource[]>();
  const [error, setError] = useState();

  useEffect(() => {
    api.API.get(getEndpointURL(Endpoint.CurrencyExchangeRateList), {
      searchParams: { source: source || "", target: target || "" },
    })
      .then((resp) => resp.json())
      .then(
        ({ currency_exchange_rates }: CurrencyExchangeRateListResponseBody) =>
          setCurrencyExchangeRateList(currency_exchange_rates)
      )
      .catch((err) => {
        setError(err);
      });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { currencyExchangeRateList, error };
};

export default useFetchCurrencyExchangeRateList;
