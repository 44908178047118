import { Tooltip, TooltipTriggerProps } from "@gocardless/flux-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

interface MessageComponentProps {
  onClose: () => void;
  message: string;
}
export const MessageComponent = ({
  onClose,
  message,
}: MessageComponentProps) => {
  // when the tooltip dismounts, it will trigger the useEffect cleanup, hence reset the message
  useEffect(() => onClose, [onClose]);
  return <>{message}</>;
};

interface ShareWrapperProps {
  onClick: () => void;
  children: (
    props: TooltipTriggerProps & { isActive: boolean }
  ) => React.ReactNode;
}
export const ShareWrapper = ({ onClick, children }: ShareWrapperProps) => {
  const timeoutActive = useRef<number>();
  const [isActive, setIsActive] = useState(false);

  const onClose = useCallback(() => {
    setIsActive(false);
  }, []);

  useEffect(() => {
    if (isActive) {
      timeoutActive.current = window.setTimeout(() => {
        setIsActive(false);
      }, 3000);
    }
    return () => {
      window.clearTimeout(timeoutActive.current);
    };
  }, [isActive]);

  const handleClick = () => {
    onClick();
    setIsActive(true);
  };

  const { i18n } = useLingui();
  const linkCopiedMessage = i18n._(
    t({
      id: "request-payment.paylinkslist-share-icon-button.tooltip-link-copied-message",
      message: "Link copied",
    })
  );
  const linkCopyMessage = i18n._(
    t({
      id: "request-payment.paylinkslist-share-icon-button.tooltip-link-copy-message",
      message: "Copy",
    })
  );

  return (
    <Tooltip
      message={
        <MessageComponent
          message={isActive ? linkCopiedMessage : linkCopyMessage}
          onClose={onClose}
        />
      }
      placement="bottom"
    >
      {(triggerProps) =>
        children({ ...triggerProps, isActive, onClick: handleClick })
      }
    </Tooltip>
  );
};
