import { FC, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { Box, CSSRulesFunction, useTheme } from "@gocardless/flux-react";
/* eslint-disable import/no-unresolved */
import CustomerCreateDe from "src/assets/png/preview-customer-create-de@2x.png";
import CustomerCreateEs from "src/assets/png/preview-customer-create-es@2x.png";
import CustomerCreateFr from "src/assets/png/preview-customer-create-fr@2x.png";
/* eslint-enable import/no-unresolved */
import { UserLocale, useI18n } from "src/technical-integrations/i18n";

const animationStyle: CSSRulesFunction = (theme) => ({
  svg: {
    borderRadius: theme.radius(2),
  },
});

const CreatePaymentAnimation: FC = () => {
  const { theme } = useTheme();
  const [locale] = useI18n();
  const [animationData, setAnimationData] = useState<object | null>(null);

  useEffect(() => {
    if (typeof jest !== "undefined") return;
    if (locale !== UserLocale.EN_GB) return;

    import("./create-payment-animation.lottie.json").then((data) =>
      setAnimationData(data)
    );
  }, [locale]);

  if (locale === UserLocale.EN_GB || locale === UserLocale.EN_US) {
    if (!animationData) return null;

    return (
      <Lottie
        animationData={animationData}
        loop
        css={[animationStyle(theme)]}
      />
    );
  } else {
    const src = {
      [UserLocale.DE_DE]: CustomerCreateDe,
      [UserLocale.ES_ES]: CustomerCreateEs,
      [UserLocale.FR_FR]: CustomerCreateFr,
    }[locale];

    return (
      <Box maxWidth={550}>
        <img src={src} alt="Create customer" css={{ maxWidth: "100%" }} />
      </Box>
    );
  }
};

export default CreatePaymentAnimation;
