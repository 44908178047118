import { CSSRulesFunction } from "@gocardless/flux-react";

export const separatorStyle: CSSRulesFunction<{ tallSeparator: boolean }> = (
  theme,
  { tallSeparator }
) => [
  theme.responsive(
    ["scaleY(1.8) translateY(-5px)", null, "none"],
    (transform) => (tallSeparator ? { transform } : {})
  ),
];
