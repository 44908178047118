import { XYGrid } from "@gocardless/flux-react";
import { PropsWithChildren } from "react";
export const Section: React.FC<PropsWithChildren> = ({ children }) => (
  <XYGrid
    width="100%"
    templateColumns={["1fr", null, "repeat(auto-fit, minmax(96px, 1fr))"]}
    columnGap={2}
    rowGap={1}
  >
    {children}
  </XYGrid>
);
