export function formatCurrencyAndSplit({
  locale,
  value,
  separateBy,
  options,
}: {
  locale: string;
  value: number;
  separateBy: Intl.NumberFormatPart["type"];
  options: Intl.NumberFormatOptions;
}) {
  // an example to illustrate the rationale behind this helper if the number
  // 442,500.00 needs to be locale formatted but to split at the '.' to obtain
  // '442,500' and '.00'

  if (value < 0.01 && options.style === "percent") {
    options.minimumFractionDigits = 2;
  }

  const formatter = new Intl.NumberFormat(locale, options);

  // get decimal separator ('.' for en-GB)
  const separator =
    formatter.formatToParts(value).find(({ type }) => type === separateBy)
      ?.value ?? "";

  // The seperator will be used as a regex to split the formatted number,
  // special characters will need to be escaped e.g. '.' => '\.' to avoid
  // confusion with '.' regex char which matches to any character
  const escapedSeparator = separator.replace(".", "\\.");
  const regex = new RegExp(`(?=${escapedSeparator})`, "g");

  // get formatted string ('442,500.00' for en-GB)
  const numberString = formatter.format(value);
  // split the formatted string into integer (442,500) and fraction (.00 GBP)
  // parts
  return numberString.split(regex);
}
