import { Trans } from "@lingui/macro";
import { OutboundPaymentBalanceType } from "src/components/HomeOutboundPaymentBalance/OutboundPaymentBalanceType";

export const OutboundDataWidgetTooltipLabel: React.FC<{
  type: OutboundPaymentBalanceType;
}> = ({ type }) => {
  switch (type) {
    case OutboundPaymentBalanceType.Current:
      return <Trans>About current balance</Trans>;
    case OutboundPaymentBalanceType.Available:
      return <Trans>About available funds</Trans>;
    case OutboundPaymentBalanceType.Scheduled:
      return <Trans>About scheduled payments</Trans>;
    default:
      return <></>;
  }
};
