import { FC } from "react";
import {
  Box,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  P,
  Space,
  Tooltip,
  Text,
} from "@gocardless/flux-react";
import { useI18n } from "src/technical-integrations/i18n";
import { formatAndSplit } from "src/components/routes/ProtectPlus/RiskSettings/ChangeRiskSettings/EstimatedImpact/utils";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { Trans } from "@lingui/macro";
import { useReportingContext } from "src/components/ui/Reporting/components/ReportingContextProvider";
import { reportingChartTypes } from "src/components/ui/Reporting/components/ChartWidget/utils";
import { useFetchTimeSeriesData } from "src/components/ui/Reporting/components/ChartWidget/useFetchTimeSeriesData";
import { useOrganisation } from "src/queries/organisation";
import { Currency } from "src/common/currencies";

interface CollectedPaymentsProps {
  status: CreditorsVerificationStatus;
}

const CollectedPayments: FC<CollectedPaymentsProps> = ({ status }) => {
  const [locale] = useI18n();
  const organisation = useOrganisation();

  const formatDate = (date: Date) => date.toLocaleString().split("T")[0]; // Extract the date part
  const createdAt = organisation?.created_at
    ? formatDate(organisation.created_at)
    : undefined;

  const { selectedCurrency } = useReportingContext();
  const { dataPointsSum } = useFetchTimeSeriesData({
    chartType: reportingChartTypes.collectedPayments,
    startDate: createdAt,
    endDate: new Date().toISOString().split("T")[0],
    currency: (organisation?.domestic_currency as Currency) ?? Currency.Gbp,
  });

  const [collectedPaymentsInteger, collectedPaymentsFraction] = formatAndSplit({
    locale,
    value: dataPointsSum,
    separateBy: "decimal",
    options: {
      style: "decimal",
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  });

  return (
    <Box minWidth="200px">
      <P
        layout="inline"
        size={3}
        color={ColorPreset.TextOnLight_01}
        css={{
          verticalAlign: "text-bottom",
          fontFamily: "Para",
          fontWeight: 300,
        }}
      >
        <Trans>Collected payments</Trans>
      </P>

      <Space h={0.25} layout="inline" />

      <Tooltip
        tooltipId="collected-payments-tooltip"
        message={
          <Text size={1}>
            {status === CreditorsVerificationStatus.Successful ? (
              <Trans>
                Total funds successfully collected from your customers
              </Trans>
            ) : (
              <Trans>
                Total funds successfully collected from your customers. Activate
                payouts to get these funds paid into your bank account.
              </Trans>
            )}
          </Text>
        }
      >
        <Icon
          name={Glyph.Tooltip}
          color={ColorPreset.TextOnLight_01}
          size="16px"
        />
      </Tooltip>

      <Space v={0.5} />

      <P weight={FontWeight.SemiBold}>
        <Text size={[7, null, 9]}>{collectedPaymentsInteger}</Text>
        <Text
          size={[5, null, 6]}
        >{`${collectedPaymentsFraction} ${selectedCurrency}`}</Text>
      </P>
    </Box>
  );
};

export default CollectedPayments;
