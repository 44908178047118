import { Box, Color, Overflow } from "@gocardless/flux-react";

export const EmptyListState = () => (
  <Box layout="flex" flexDirection="column" overflowX={Overflow.Hidden}>
    <Box height={9} width="78px" bg={Color.Greystone_600} borderRadius={2} />
    <Box
      spaceAbove={1}
      height={9}
      width="154px"
      bg={Color.Greystone_100}
      borderRadius={2}
    />
    <Box
      spaceAbove={1}
      height={9}
      width="117px"
      bg={Color.Greystone_100}
      borderRadius={2}
    />
  </Box>
);
