import { AlignItems, Box, ColorPreset, Overflow } from "@gocardless/flux-react";
import { PropsWithChildren } from "react";

export const HomepageCard: React.FC<
  PropsWithChildren & { noGutterOnMobile?: boolean }
> = ({ children, noGutterOnMobile }) => (
  <Box
    bg={ColorPreset.BackgroundLight_01}
    borderColor={ColorPreset.BorderOnLight_04}
    borderWidth={1}
    borderRadius={1.5}
    gutterH={[noGutterOnMobile ? 0 : 1.5, null, 2]}
    gutterV={[noGutterOnMobile ? 0 : 1.5, null, 2]}
    layout="flex"
    flexDirection="column"
    flexGrow={1}
    overflowY={Overflow.Hidden}
    overflowX={Overflow.Hidden}
    alignItems={AlignItems.Center}
  >
    {children}
  </Box>
);
