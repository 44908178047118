import { Trans } from "@lingui/macro";
import { useToggle } from "react-use";
import { DialogProps } from "@gocardless/flux-react";

import { ActionComponentProps, SuggestedActionType } from "../types";
/**
In essence, this HOC adds a dialog functionality to a given component, 
allowing it to be opened and closed by clicking on its children.
 */
export const withChildrenTarget =
  (
    DialogComponent: React.FC<
      Pick<DialogProps, "open" | "closeAction"> & { id: SuggestedActionType }
    >
  ) =>
  ({ children, id }: ActionComponentProps) => {
    const [isOpen, toggleDialog] = useToggle(false);

    return (
      <>
        {children({ onClick: toggleDialog })}
        <DialogComponent
          id={id}
          open={isOpen}
          closeAction={{
            label: <Trans id="Close">Close</Trans>,
            onClose: toggleDialog,
          }}
        />
      </>
    );
  };
