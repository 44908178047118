import { FpjGetStartedType } from "@gocardless/api/dashboard/types";
import {
  Box,
  ButtonVariant,
  FontWeight,
  HoverEffect,
  Interpose,
  PlainLink,
  Separator,
  Space,
  Text,
  XYGrid,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";

import NeedHelpCard from "./NeedHelpCard";

interface NeedHelpProps {
  getStartedType?: FpjGetStartedType;
  kccVariantEnabled: boolean;
}

const NeedHelp: React.FC<NeedHelpProps> = ({
  getStartedType,
  kccVariantEnabled,
}) => {
  const { sendEvent } = useSegment();

  return (
    <>
      <Text size={[5, null, 7]} weight={FontWeight.SemiBold}>
        <Trans id="home.need-help">Need help?</Trans>
      </Text>

      <Space v={2} />

      <XYGrid
        templateColumns={["1fr", null, "repeat(3, 1fr)"]}
        rowGap={1.5}
        columnGap={2}
      >
        {kccVariantEnabled ? (
          <>
            <NeedHelpCard.WatchOurGettingStartedVideos />
            <NeedHelpCard.BookAMeeting />
            <NeedHelpCard.ConnectToYourExistingSoftware />
          </>
        ) : (
          <>
            {(getStartedType === FpjGetStartedType.Dashboard ||
              getStartedType === FpjGetStartedType.Skip) && (
              <>
                <NeedHelpCard.BookAMeeting />
                <NeedHelpCard.ConnectToYourExistingSoftware />
                <NeedHelpCard.SearchOurKnowledgeBase />
              </>
            )}

            {getStartedType === FpjGetStartedType.Api && (
              <>
                <NeedHelpCard.ViewOurDeveloperDocs />
                <NeedHelpCard.BrandYourPaymentPage />
                <NeedHelpCard.CreateASandboxTestingAccount />
              </>
            )}

            {getStartedType === FpjGetStartedType.App && (
              <>
                <NeedHelpCard.BookAMeeting />
                <NeedHelpCard.BrandYourPaymentPage />
                <NeedHelpCard.SearchOurKnowledgeBase />
              </>
            )}
          </>
        )}
      </XYGrid>

      {kccVariantEnabled ? (
        <Box spaceAbove={2}>
          <Interpose
            node={
              <Separator direction="inline-vertical" spacing={[[0.5, 1]]} />
            }
          >
            <PlainLink
              href="https://hub.gocardless.com/s/knowledge-base/"
              target="_blank"
              effect={HoverEffect.TextDecoration}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_LINK_CLICKED,
                  { target: "customer_knowledge_base" }
                );
              }}
            >
              <Trans>Customer knowledge base</Trans>
            </PlainLink>

            <ZendeskLink
              data-tracking-label="KCC homepage footer"
              variant={ButtonVariant.Inline}
              formId={134125}
              css={{ fontWeight: "normal" }}
            >
              <Trans>Support</Trans>
            </ZendeskLink>

            <PlainLink
              href="https://developer.gocardless.com/"
              target="_blank"
              effect={HoverEffect.TextDecoration}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_LINK_CLICKED,
                  { target: "api_documentation" }
                );
              }}
            >
              <Trans>API documentation</Trans>
            </PlainLink>

            <PlainLink
              href="https://gocardless.com/faq/merchants/"
              target="_blank"
              effect={HoverEffect.TextDecoration}
              onClick={() => {
                sendEvent(
                  TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_LINK_CLICKED,
                  { target: "faqs" }
                );
              }}
            >
              <Trans>FAQs</Trans>
            </PlainLink>
          </Interpose>
        </Box>
      ) : null}
    </>
  );
};

export default NeedHelp;
