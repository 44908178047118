import { Trans } from "@lingui/macro";
import { useI18n } from "src/technical-integrations/i18n";
import { formatTimeAgo } from "src/common/relativeDate";

interface LastUpdatedTextProps {
  dateTimeString: string;
}

export const LastUpdatedText: React.FC<LastUpdatedTextProps> = ({
  dateTimeString,
}) => {
  const [locale] = useI18n();

  return (
    <>
      <Trans id="home.last-updated">Last updated</Trans>{" "}
      {formatTimeAgo(dateTimeString, locale)}
    </>
  );
};
