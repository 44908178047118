import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Box, FontWeight, Text, TypePreset } from "@gocardless/flux-react";

interface AnimatedLoadingTextProps {
  time: number;
  strings: string[];
}

export const AnimatedLoadingText = ({
  time,
  strings,
}: AnimatedLoadingTextProps) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % strings.length);
    }, time);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            {strings[index]}
          </Text>
        </motion.div>
      </AnimatePresence>
    </Box>
  );
};

export default AnimatedLoadingText;
