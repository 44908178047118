import { P } from "@gocardless/flux-react";
import { ReactNode } from "react";
import { TrackingEvent } from "src/common/trackingEvents";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

import { LEARN_MORE_BASE_URL } from "../../utils/common";
import { useReportingContext } from "../ReportingContextProvider";

export const reportingChartTypes = {
  collectedPayments: "collected_payments",
  totalPaidOut: "total_paid_out",
  failedPaymentAttempts: "failed_payment_attempts",
} as const;

export interface DataPoint {
  date: Date;
  value: number;
}

export type ReportingChartType =
  (typeof reportingChartTypes)[keyof typeof reportingChartTypes];

export const useChartTypesMap: (i18n: I18n) => Record<
  ReportingChartType,
  {
    name: string;
    tooltipId: string;
    tooltipLabel: string;
    tooltipDescription: ReactNode;
    tooltipLink: string;
  }
> = (i18n) => ({
  collected_payments: {
    name: i18n._(t({ message: "Collected payments" })),
    tooltipId: "collectedPayments",
    tooltipLabel: i18n._(t({ message: "About the Collected payments chart" })),
    tooltipDescription: i18n._(
      t({
        message: "Total collected from your customers, before deducting fees",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
  total_paid_out: {
    name: i18n._(t({ message: "Total earnings" })),
    tooltipId: "totalEarnings",
    tooltipLabel: i18n._(t({ message: "About the Total earnings chart" })),
    tooltipDescription: i18n._(
      t({
        message:
          "Total paid into your bank account, after fees have been deducted",
      })
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
  failed_payment_attempts: {
    name: i18n._(t({ message: "Failed payment attempts" })),
    tooltipId: "failedPaymentAttempts",
    tooltipLabel: i18n._(
      t({ message: "About the Failed payment attempts chart" })
    ),
    tooltipDescription: (
      <>
        <P>
          {i18n._(
            t({
              message:
                "This is the total number of payment attempts that have failed during the defined time period.",
            })
          )}
        </P>
        <P>
          {i18n._(
            t({
              message:
                "As the same payment can fail and be retried multiple times, this is different from the total number of failed payments.",
            })
          )}
        </P>
      </>
    ),
    tooltipLink: LEARN_MORE_BASE_URL,
  },
});

export const getSelectedChartTypeEvent = (chartType: ReportingChartType) => {
  switch (chartType) {
    case "collected_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_TYPE_SELECTED;
    case "failed_payment_attempts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_CHART_TYPE_SELECTED;
    case "total_paid_out":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_CHART_TYPE_SELECTED;
  }
};

export const getChartTypeTooltipEvent = (chartType: ReportingChartType) => {
  switch (chartType) {
    case "collected_payments":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_COLLECTED_PAYMENTS_CHART_WIDGET_TOOLTIP_CLICKED;
    case "failed_payment_attempts":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_FAILED_PAYMENT_ATTEMPTS_TOOLTIP_CLICKED;
    case "total_paid_out":
      return TrackingEvent.MERCHANT_DASHBOARD_REPORTING_TOTAL_EARNINGS_TOOLTIP_CLICKED;
  }
};

export const useFallbackDataPoints = () => {
  const { startDateFilter, endDateFilter } = useReportingContext();

  return [
    { date: new Date(startDateFilter), value: 0 },
    { date: new Date(endDateFilter), value: 0 },
  ];
};
