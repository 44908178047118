import { useEffect } from "react";
import {
  Banner,
  BannerButtonVariant,
  BannerLayout,
  ButtonSize,
  BannerStatus,
  BannerVariant,
  Color,
  BannerLeftAccessoryType,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

interface ErrorBannerProps {
  onReload: () => void;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = ({ onReload }) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    sendEvent(TrackingEvent.MERCHANT_DASHBOARD_REPORTING_ERROR_BANNER_VIEWED);
  }, [sendEvent]);

  return (
    <Banner
      backgroundColor={Color.Alert_50}
      layout={BannerLayout.Horizontal}
      primaryAction={{
        buttonVariant: BannerButtonVariant.Secondary,
        children: <Trans>Reload</Trans>,
        control: "button",
        onClick: onReload,
        size: ButtonSize.Sm,
      }}
      secondaryAction={{
        buttonVariant: BannerButtonVariant.Plain,
        children: <Trans>Contact support</Trans>,
        control: "link",
        href: "https://manage.gocardless.com/forms/zendesk/134125",
        size: ButtonSize.Sm,
      }}
      leftAccessory={{
        type: BannerLeftAccessoryType.Status,
        status: BannerStatus.Alert,
      }}
      variant={BannerVariant.Light}
    >
      <Trans>
        There was an issue loading some of your data. Reload or contact support
        if the problem persists.
      </Trans>
    </Banner>
  );
};
