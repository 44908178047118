import {
  AlignItems,
  Box,
  BoxProps,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  PlainButton,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { ReactNode, useRef, useState } from "react";
import { useToggle } from "react-use";
import { useResizeObserver } from "src/utils/geometry";

export enum Transition {
  None = "0s",
  Quick = "0.2s",
  Regular = "0.3s",
}
export interface CollapsiblePanelProps {
  invertTrigger?: boolean;
  trigger: (triggerProps: CollapsiblePanelTriggerProps) => ReactNode;
  defaultOpen?: boolean;
  transition?: Transition;
  children: ReactNode;
  onOpenStateChange?: (isOpen: boolean) => void;
  collapsedContent?: ReactNode;
}

export interface CollapsiblePanelTriggerProps {
  onClick: () => void;
  "aria-expanded": boolean;
  isOpen: boolean;
}

export const SimpleCollapsible: React.FC<CollapsiblePanelProps> = (props) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const [childrenHeight, setChildrenHeight] = useState<number>(
    innerRef.current?.scrollHeight ?? 0
  );

  const {
    trigger,
    children,
    invertTrigger,
    defaultOpen,
    transition,
    onOpenStateChange,
    collapsedContent,
  } = props;

  const [isOpen, toggleOpen] = useToggle(defaultOpen ?? false);

  const onClick = () => {
    onOpenStateChange?.(!isOpen);
    toggleOpen();
  };
  const triggerProps = {
    onClick,
    "aria-expanded": isOpen,
    isOpen,
  };

  const resizeCallback = () => {
    const element = innerRef.current;

    setChildrenHeight(element?.scrollHeight ?? 0);
  };

  useResizeObserver(innerRef, resizeCallback, []);

  return (
    <>
      {!invertTrigger ? trigger(triggerProps) : null}
      <Box
        css={{
          maxHeight: isOpen ? childrenHeight + 2 : 0,
          transition: `max-height ${transition ?? Transition.Regular}`,
          overflow: "hidden",
        }}
      >
        {<Box ref={innerRef}>{children}</Box>}
      </Box>
      {invertTrigger ? trigger(triggerProps) : null}
      {!isOpen && !!collapsedContent ? collapsedContent : null}
    </>
  );
};

/**
 *
 * @param props
 * @description
 * A simple SimpleCollapsibleTrigger that can be used in conjunction with the SimpleCollapsible
 * @returns
 */
export const SimpleCollapsibleTrigger: React.FC<
  CollapsiblePanelTriggerProps & Omit<BoxProps, "onClick">
> = ({ ...props }) => {
  const { onClick, "aria-expanded": ariaExpanded, isOpen, ...boxProps } = props;
  return (
    <PlainButton
      effect={HoverEffect.TextDecoration}
      css={{ width: "100%" }}
      onClick={onClick}
      aria-expanded={ariaExpanded}
    >
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        width="100%"
        gutterV={1}
        {...boxProps}
      >
        <Box flexGrow={1}>
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            {isOpen ? "Show less" : "Show more"}
          </Text>
        </Box>
        <Icon name={isOpen ? Glyph.ChevronUp : Glyph.ChevronDown} size="14px" />
      </Box>
    </PlainButton>
  );
};
