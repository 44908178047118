import {
  CalendarDate,
  CalendarDateTime,
  endOfMonth,
  endOfWeek,
  getLocalTimeZone,
  startOfMonth,
  startOfWeek,
  toZoned,
  today,
} from "@internationalized/date";
import { I18n } from "@lingui/core";
import { t } from "@lingui/macro";

export enum DateFilter {
  Anytime = "anytime",
  Today = "today",
  Yesterday = "yesterday",
  ThisWeek = "this_week",
  LastWeek = "last_week",
  ThisMonth = "this_month",
  LastMonth = "last_month",
  SevenDays = "last_7_days",
  ThirtyDays = "last_30_days",
  Custom = "custom",
}

export const getDateRangeToString = (
  i18n: I18n,
  dateFilter: DateFilter
): string => {
  const dateRangeToString: Record<DateFilter, string> = {
    [DateFilter.Today]: i18n._(
      t({
        id: "date.filter.today",
        message: "Today",
      })
    ),
    [DateFilter.Yesterday]: i18n._(
      t({
        id: "date.filter.yesterday",
        message: "Yesterday",
      })
    ),
    [DateFilter.ThisWeek]: i18n._(
      t({
        id: "date.filter.this.week",
        message: "This week",
      })
    ),
    [DateFilter.LastWeek]: i18n._(
      t({
        id: "date.filter.last.week",
        message: "Last week",
      })
    ),
    [DateFilter.ThisMonth]: i18n._(
      t({
        id: "date.filter.this.month",
        message: "This month",
      })
    ),
    [DateFilter.LastMonth]: i18n._(
      t({
        id: "date.filter.last.month",
        message: "Last month",
      })
    ),
    [DateFilter.SevenDays]: i18n._(
      t({
        id: "date.filter.last.7.days",
        message: "Last 7 days",
      })
    ),
    [DateFilter.ThirtyDays]: i18n._(
      t({
        id: "date.filter.last.30.days",
        message: "Last 30 days",
      })
    ),
    [DateFilter.Anytime]: i18n._(
      t({
        id: "date.filter.anytime",
        message: "Anytime",
      })
    ),
    [DateFilter.Custom]: i18n._(
      t({
        id: "date.filter.choose.dates",
        message: "Choose dates",
      })
    ),
  };

  return dateRangeToString[dateFilter];
};

export const getDatesFromFilter = (filter: DateFilter, locale: string) => {
  const now = today(getLocalTimeZone());

  let startDate: CalendarDate;
  let endDate: CalendarDate;

  switch (filter) {
    case DateFilter.Today:
      startDate = now;
      endDate = now;
      break;
    case DateFilter.Yesterday:
      startDate = now.subtract({ days: 1 });
      endDate = now.subtract({ days: 1 });
      break;
    case DateFilter.ThisWeek:
      startDate = startOfWeek(now, locale);
      endDate = endOfWeek(now, locale);
      break;
    case DateFilter.LastWeek:
      startDate = startOfWeek(now.subtract({ weeks: 1 }), locale);
      endDate = endOfWeek(now.subtract({ weeks: 1 }), locale);
      break;
    case DateFilter.ThisMonth:
      startDate = startOfMonth(now);
      endDate = endOfMonth(now);
      break;
    case DateFilter.LastMonth:
      startDate = startOfMonth(now.subtract({ months: 1 }));
      endDate = endOfMonth(now.subtract({ months: 1 }));
      break;
    case DateFilter.SevenDays:
      startDate = now.subtract({ days: 7 });
      endDate = now;
      break;
    case DateFilter.ThirtyDays:
      startDate = now.subtract({ days: 30 });
      endDate = now;
      break;
    case DateFilter.Anytime:
    case DateFilter.Custom:
      return {};
    default:
      return {};
  }

  return { startDate, endDate };
};

export const preDefinedDateFilters = [
  DateFilter.Today,
  DateFilter.Yesterday,
  DateFilter.ThisWeek,
  DateFilter.LastWeek,
  DateFilter.ThisMonth,
  DateFilter.LastMonth,
  DateFilter.SevenDays,
  DateFilter.ThirtyDays,
];

const allDateRanges = Object.values(DateFilter) as Array<DateFilter>;

export const popularDateRanges = [
  DateFilter.Anytime,
  DateFilter.Today,
  DateFilter.Yesterday,
  DateFilter.ThisWeek,
  DateFilter.ThisMonth,
];
export const dateRangesSortedByPopularity = popularDateRanges.concat(
  allDateRanges.filter(
    (popularDateRange) => !popularDateRanges.includes(popularDateRange)
  )
);

export const getCalendarDateTime = (
  year: number,
  month: number,
  date: number,
  hour = 0,
  min = 0,
  sec = 0
) =>
  toZoned(
    new CalendarDateTime(year, month, date, hour, min, sec),
    getLocalTimeZone()
  );
