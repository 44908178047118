import {
  Box,
  Color,
  ColorPreset,
  Shimmer,
  Space,
  useTheme,
} from "@gocardless/flux-react";

import { SuggestedActionProps } from "../useSuggestedActions";

import { SuggestedActionTile } from "./SuggestedActionTile";

export const SuggestedActionTileWrapper = ({
  isLoading,
  ActionComponent,
  link,
  ...props
}: SuggestedActionProps & { isLoading: boolean; index: number }) => {
  const { theme } = useTheme();
  if (isLoading) {
    return (
      <Box
        gutterH={[1.5, null, null, 1.5]}
        gutterV={1.5}
        bg={[Color.Transparent, null, null, Color.White]}
        borderRadius={[0, null, null, 1]}
        borderWidth={[0, null, null, 1]}
        borderColor={ColorPreset.BorderOnLight_04}
        height={120}
      >
        <Shimmer width="70%" height={theme.spacing(1)} borderRadius={1} />
        <Space v={1} />
        <Shimmer height={theme.spacing(0.75)} borderRadius={1} />
        <Space v={0.5} />
        <Shimmer width="25%" height={theme.spacing(0.75)} borderRadius={1} />
      </Box>
    );
  }
  if (typeof link !== "undefined") {
    return (
      <Box key={props.id} gutterH={[1.5, null, null, 0]}>
        <SuggestedActionTile
          key={props.id}
          onClick={undefined}
          link={link}
          {...props}
        />
      </Box>
    );
  }

  return (
    <Box key={props.id} gutterH={[1.5, null, null, 0]}>
      <ActionComponent id={props.id}>
        {({ onClick }) => (
          <SuggestedActionTile
            key={props.id}
            link={undefined}
            onClick={onClick}
            {...props}
          />
        )}
      </ActionComponent>
    </Box>
  );
};
