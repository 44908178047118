import { Trans } from "@lingui/macro";

import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";

export const OutboundPaymentBalanceCardTooltipLabelCopy: React.FC<{
  type: OutboundPaymentBalanceType;
}> = ({ type }) => {
  switch (type) {
    case OutboundPaymentBalanceType.Current:
      return <Trans>See more information about current balance.</Trans>;
    case OutboundPaymentBalanceType.Available:
      return <Trans>See more information about available funds.</Trans>;
    case OutboundPaymentBalanceType.Scheduled:
      return <Trans>See more information about scheduled payments.</Trans>;
    default:
      return <></>;
  }
};
