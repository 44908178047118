import {
  isMFAEnforcementDataLoaded,
  useMFAEnforcementData,
} from "src/components/authentication/mfa/useMFAEnforcementData";
import { MFAEnforcementDelayedBanner } from "src/components/routes/Home/components/MFAEnforcementDelayedBanner";

export const TopBanners: React.FC = () => {
  const mfaData = useMFAEnforcementData();

  if (!isMFAEnforcementDataLoaded(mfaData)) {
    return null;
  }
  return <MFAEnforcementDelayedBanner />;
};
