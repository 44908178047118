import { Route, RouteParams } from "src/common/routing/routes";
import { TrackingEvent } from "src/common/trackingEvents";

export enum LastActivityType {
  TopOneOffPayments = "top-one-off-payments",
  TopSubscriptionTemplates = "top-subscription-templates",
  RecentCustomers = "recent-customers",
}

export interface ListProps {
  title: React.ReactNode;
  buttonName: React.ReactNode;
  route: Route;
  routeParams?: RouteParams;
  queryParams?: Record<string, string>;
  event: TrackingEvent;
  emptyMessage: React.ReactNode;
}

export const MAX_LISTITEMS = 5;
