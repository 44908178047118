import {
  AlignItems,
  Box,
  Color,
  ColorPreset,
  FontWeight,
  Glyph,
  Icon,
  JustifyContent,
  Layer,
  PlainButton,
  PlainLink,
  Space,
  Text,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";
import { LinkBuilder, Route } from "src/components/routing";
import { useRouter } from "next/router";
import { Trans } from "@lingui/macro";
import { colorToRgba } from "@react-spring/shared";

/**
 * The total height of the header. Needed for allowing for space in the layout component.
 */
export const HEADER_HEIGHT = "106px";

export interface HeaderProps {
  progressBar?: React.ReactNode;
  title?: React.ReactNode;
  showExitButton?: boolean;
  showBackButton?: boolean;
}

const getRgbaColorWithOpacity = (hexColor: string, opacity: number): string => {
  const rgbaColor = colorToRgba(hexColor);
  return typeof rgbaColor === "string"
    ? rgbaColor.substring(0, rgbaColor.length - 2) + opacity.toString() + ")"
    : "";
};

export const Header: React.FC<HeaderProps> = ({
  title,
  progressBar,
  showExitButton = true,
  showBackButton = true,
}) => {
  const router = useRouter();
  const { theme } = useTheme();

  const borderColor = getRgbaColorWithOpacity(
    theme.color(Color.Greystone_1400),
    0.16
  );

  return (
    <Box
      bg={ColorPreset.BackgroundLight_02}
      borderWidth={1}
      css={{
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderTopWidth: 0,
        borderColor: borderColor,
      }}
    >
      <Layer mode="relative">
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={
            !showBackButton && showExitButton
              ? JustifyContent.FlexEnd
              : JustifyContent.SpaceBetween
          }
          gutterH={1.5}
          gutterV={2}
        >
          {showBackButton ? (
            <PlainButton onClick={router.back}>
              <Box layout="flex" alignItems={AlignItems.Center}>
                <Icon name={Glyph.ArrowBack} />
                <Space layout="inline" h={0.5} />
                <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
                  {title || <Trans id="Back">Back</Trans>}
                </Text>
              </Box>
            </PlainButton>
          ) : null}

          {showExitButton ? (
            <LinkBuilder route={Route.Home}>
              {(result) => (
                <PlainLink {...result} textDecoration="underline">
                  <Text weight={FontWeight.Bold} preset={TypePreset.Body_01}>
                    <Trans id="Exit">Exit</Trans>
                  </Text>
                </PlainLink>
              )}
            </LinkBuilder>
          ) : (
            <Box />
          )}
        </Box>
      </Layer>
      {progressBar}
    </Box>
  );
};
