import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";

export enum DateRange {
  Today = "today",
  Yesterday = "yesterday",
  ThisWeek = "this_week",
  LastWeek = "last_week",
  ThisMonth = "this_month",
  LastMonth = "last_month",
  Last3Months = "last_3_months",
  Last7Days = "last_7_days",
  Last30Days = "last_30_days",
  Last90Days = "last_90_days",
  Between = "between",
  Custom = "custom",
}

export const dateFilterToText = (): Record<DateRange, string> => ({
  [DateRange.Today]: i18n._(t({ id: "date.filter.today", message: "Today" })),
  [DateRange.Yesterday]: i18n._(
    t({ id: "date.filter.yesterday", message: "Yesterday" })
  ),
  [DateRange.ThisWeek]: i18n._(
    t({ id: "date.filter.this.week", message: "This week" })
  ),
  [DateRange.LastWeek]: i18n._(
    t({ id: "date.filter.last.week", message: "Last week" })
  ),
  [DateRange.ThisMonth]: i18n._(
    t({ id: "date.filter.this.month", message: "This month" })
  ),
  [DateRange.LastMonth]: i18n._(
    t({ id: "date.filter.last.month", message: "Last month" })
  ),
  [DateRange.Last3Months]: i18n._(t({ message: "Last 3 months" })),
  [DateRange.Last7Days]: i18n._(
    t({ id: "date.filter.last.7.days", message: "Last 7 days" })
  ),
  [DateRange.Last30Days]: i18n._(
    t({ id: "date.filter.last.30.days", message: "Last 30 days" })
  ),
  [DateRange.Last90Days]: i18n._(t({ message: "Last 90 days" })),
  [DateRange.Between]: i18n._(
    t({ id: "date.filter.between", message: "Between" })
  ),
  [DateRange.Custom]: i18n._(
    t({ id: "date.filter.custom", message: "Custom" })
  ),
});

export function dateFilterToAPIParams(
  date: DateRange,
  key: string,
  gte?: string,
  lte?: string,
  format?: string
) {
  let startDate;
  let endDate;

  switch (date) {
    case DateRange.Today:
      startDate = moment().clone().startOf("day");
      endDate = moment().clone().endOf("day");
      break;
    case DateRange.Yesterday:
      startDate = moment().clone().subtract(1, "days").startOf("day");
      endDate = moment().clone().subtract(1, "days").endOf("day");
      break;
    case DateRange.ThisWeek:
      startDate = moment().clone().startOf("isoWeek");
      endDate = moment().clone().endOf("isoWeek");
      break;
    case DateRange.LastWeek:
      startDate = moment().clone().subtract(1, "weeks").startOf("isoWeek");
      endDate = moment().clone().subtract(1, "weeks").endOf("isoWeek");
      break;
    case DateRange.ThisMonth:
      startDate = moment().clone().startOf("month");
      endDate = moment().clone().endOf("month");
      break;
    case DateRange.LastMonth:
      startDate = moment().clone().subtract(1, "months").startOf("month");
      endDate = moment().clone().subtract(1, "months").endOf("month");
      break;
    case DateRange.Last7Days:
      startDate = moment().clone().subtract(7, "days").startOf("day");
      endDate = moment().clone().endOf("day");
      break;
    case DateRange.Last30Days:
      startDate = moment().clone().subtract(30, "days").startOf("day");
      endDate = moment().clone().endOf("day");
      break;
    case DateRange.Last90Days:
      startDate = moment().clone().subtract(90, "days").startOf("day");
      endDate = moment().clone().endOf("day");
      break;
    case DateRange.Between: {
      startDate = _.isString(gte) ? moment(gte).startOf("day") : undefined;
      endDate = _.isString(lte) ? moment(lte).endOf("day") : undefined;
      break;
    }
  }

  return {
    [`${key}[gte]`]: startDate
      ? format
        ? startDate.format(format)
        : startDate.toISOString()
      : undefined,
    [`${key}[lte]`]: endDate
      ? format
        ? endDate.format(format)
        : endDate.toISOString()
      : undefined,
  };
}

export function availableAPIParams(key: string) {
  return [`${key}`, `${key}[gt]`, `${key}[gte]`, `${key}[lt]`, `${key}[lte]`];
}

export function availableQueryParams(key: string, onlyDates = false) {
  if (onlyDates) return [`${key}_gte`, `${key}_lte`];

  return [`${key}`, `${key}_gte`, `${key}_lte`];
}

export function getQueryParamsFromAPIParams(
  params: Record<string, string | undefined>,
  paramKey: string
) {
  let queryParams = {};
  Object.keys(params).forEach((key) => {
    queryParams = {
      ...queryParams,
      [apiParamsMappings(paramKey)[key] as string]: params[key] as string,
    };
  });

  return queryParams;
}

export function buildAPIParamsFromQueryParams(
  params: Record<string, string | undefined>,
  paramKey: string,
  format?: string
) {
  const dateFilterParams = _.pick(params, availableQueryParams(paramKey));

  const onlyDateParams = availableQueryParams(paramKey, true);
  const apiParams = dateFilterToAPIParams(
    dateFilterParams[paramKey] as DateRange,
    paramKey,
    dateFilterParams[onlyDateParams[0] as string],
    dateFilterParams[onlyDateParams[1] as string],
    format
  );
  return apiParams;
}

export function getAPIParamsFromQueryParams(
  params: Record<string, string | undefined>,
  paramKey: string
) {
  let apiParams = {};
  Object.keys(params).forEach((key) => {
    apiParams = {
      ...apiParams,
      [Object.keys(apiParamsMappings(paramKey)).find(
        (ky) => apiParamsMappings(paramKey)[ky] === key
      ) as string]: params[key] as string,
    };
  });

  return apiParams;
}

export const apiParamsMappings = (key: string): Record<string, string> => ({
  [`${key}[lte]`]: `${key}_lte`,
  [`${key}[gte]`]: `${key}_gte`,
});
