import { FC, MouseEventHandler, PropsWithChildren } from "react";
import {
  AlignItems,
  Box,
  Color,
  Glyph,
  HoverEffect,
  Icon,
  Interpose,
  PlainButton,
  Separator,
  Text,
  VisuallyHidden,
  useTheme,
} from "@gocardless/flux-react";

import { buttonStyle } from "./Checklist.styles";

interface ItemProps {
  completed: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onCompleteClick?: MouseEventHandler<HTMLButtonElement>;
}

const Item: FC<PropsWithChildren<ItemProps>> = ({
  children,
  completed,
  onClick,
  onCompleteClick,
}) => {
  const { theme } = useTheme();

  return (
    <Box
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Stretch}
      spaceAbove={0.75}
      spaceBelow={0.75}
    >
      <Icon
        name={Glyph.TickCircle}
        color={completed ? Color.Ultraviolet_700 : Color.Greystone_600}
        size="32px"
      />

      <Box
        layout="flex"
        flexDirection="row"
        flexGrow={1}
        alignItems={AlignItems.Stretch}
        spaceBefore={0.75}
      >
        <PlainButton
          onClick={onClick}
          effect={HoverEffect.TextDecoration}
          css={[buttonStyle(theme, { completed })]}
        >
          <Text>{children}</Text>
        </PlainButton>
      </Box>

      {!completed && onCompleteClick ? (
        <PlainButton onClick={onCompleteClick}>
          <Icon name={Glyph.Close} size="10px" />
          <VisuallyHidden>Delete</VisuallyHidden>
        </PlainButton>
      ) : null}
    </Box>
  );
};

export const Checklist: FC<PropsWithChildren> & { Item: typeof Item } = ({
  children,
}) => (
  <Box>
    <Interpose node={<Separator />}>{children}</Interpose>
  </Box>
);

Checklist.Item = Item;
