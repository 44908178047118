import {
  Dialog,
  P,
  Color,
  TypePreset,
  Text,
  Glyph,
  PlainLink,
  Icon,
  FontWeight,
  Interpose,
  Space,
  Box,
  HoverEffect,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Route } from "src/common/routing";
import AddMoreCustomersDialogSVG from "src/assets/svg/suggested-actions-add-more-customers-dialog.svg";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA } from "../components/DialogCTA";

export const AddMoreCustomers = withChildrenTarget(({ ...props }) => (
  <Dialog
    {...props}
    footer={
      <DialogCTA
        route={Route.CustomersCreate}
        routeParams={{ id: "link" }}
        name={props.id}
      />
    }
  >
    <DialogBodyWrapper
      bg={Color.Ultraviolet_100}
      image={
        <Image
          src={AddMoreCustomersDialogSVG}
          alt="add-more-customers"
          width={96}
          height={96}
        />
      }
      title={<Trans>Add more customers</Trans>}
    >
      <Interpose node={<Space v={1} />}>
        <P preset={TypePreset.Body_01}>
          <Trans>
            From the <Text weight={FontWeight.SemiBold}>Customers</Text> page
            click <Text weight={FontWeight.SemiBold}>Invite customers</Text>.
            There are three main ways to add a customer:
          </Trans>
        </P>
        <Box>
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans>Share a link</Trans>
          </Text>
          <P preset={TypePreset.Body_01}>
            <Trans>
              Send an invite to customers to join via email, WhatsApp or SMS.
            </Trans>
          </P>
        </Box>
        <Box>
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans>Email</Trans>
          </Text>
          <P preset={TypePreset.Body_01}>
            <Trans>
              Send an email invite through the dashboard to your customers. You
              can customise the message you send.
            </Trans>
          </P>
        </Box>

        <Box>
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans>QR codes</Trans>
          </Text>
          <P preset={TypePreset.Body_01}>
            <Trans>
              Add customers quickly in person by printing or displaying a QR
              code.
            </Trans>
          </P>
        </Box>

        <Space v={1} />
        <PlainLink
          href="https://hub.gocardless.com/s/article/Introduction-to-adding-customers"
          target="_blank"
          effect={HoverEffect.TextDecoration}
        >
          <Text preset={TypePreset.Body_01} weight={FontWeight.SemiBold}>
            <Trans>View the help article</Trans>
          </Text>{" "}
          <Icon name={Glyph.Export} size="12px" />
        </PlainLink>
      </Interpose>
    </DialogBodyWrapper>
  </Dialog>
));
