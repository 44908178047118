import {
  Box,
  Color,
  TypePreset,
  TypeScale,
  ButtonSize,
  ButtonVariant,
  JustifyContent,
  XYGrid,
  Glyph,
  P,
  FontWeight,
  IconButton,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

interface GreatWorkBannerProps {
  onClose: () => void;
  hasDiscountExtension: boolean;
}
const GreatWorkBanner: React.FC<GreatWorkBannerProps> = ({
  onClose,
  hasDiscountExtension,
}) => (
  <Box
    bg={Color.Ultraviolet_50}
    layout="flex"
    flexDirection="row"
    gutterV={1.5}
    gutterH={1.5}
    width="100%"
    borderRadius={1.5}
  >
    <XYGrid
      // template copied from flux Banner to be consistent
      templateColumns="max-content 1fr auto"
      templateAreas={
        "'status body close' 'status body close' 'status body close'"
      }
      columnGap={1}
      rowGap={1}
      width="100%"
    >
      <Box
        gridArea="body"
        layout="flex"
        flexDirection="column"
        justifyContent={JustifyContent.Center}
      >
        <Box
          maxWidth="644px"
          gutterH={[0, null, 1.5]}
          width="100%"
          gutterV={[0, null, 0.5]}
        >
          <P
            preset={TypePreset.Heading_04}
            weight={FontWeight.SemiBold}
            size={[TypeScale.Size_03, null, TypeScale.Size_05]}
          >
            {hasDiscountExtension ? (
              <Trans id="home.great-work.description-with-discount">
                Great work! You’ve completed your set up tasks, and earned 30
                extra days without fees!
              </Trans>
            ) : (
              <Trans id="home.great-work.description">
                Great work! You’ve completed your set up tasks!
              </Trans>
            )}
          </P>
        </Box>
      </Box>
      <Box
        gridArea="close"
        layout="flex"
        justifyContent={JustifyContent.FlexEnd}
      >
        <IconButton
          label="Close"
          icon={Glyph.Close}
          variant={ButtonVariant.TextOnLight}
          size={ButtonSize.Sm}
          onClick={onClose}
          css={{
            marginTop: "-12px",
            marginRight: "-12px",
          }}
        />
      </Box>
      <Box gridArea="status" />
    </XYGrid>
  </Box>
);

export default GreatWorkBanner;
