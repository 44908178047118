import {
  Box,
  Color,
  ColorPreset,
  H6,
  HoverEffect,
  P,
  PlainButton,
  PlainLink,
  Space,
  TypePreset,
  Visibility,
} from "@gocardless/flux-react";
import Image from "next/image";
import { RouteURLParams } from "src/common/routing";
import { LinkBuilder } from "src/components/routing";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useIntersection } from "react-use";

import { SuggestedActionType } from "../types";

export interface SuggestedActionTileBaseProps {
  id: SuggestedActionType;
  message: React.ReactNode;
  title: React.ReactNode;
  imageComponent: string;
  index: number;
}

type SuggestedActionTileWithClick = SuggestedActionTileBaseProps & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  link: undefined;
};

type SuggestedActionTileWithLink = SuggestedActionTileBaseProps & {
  link: RouteURLParams | string;
  onClick: undefined;
};

export type SuggestedActionProps =
  | SuggestedActionTileWithClick
  | SuggestedActionTileWithLink;

export const SuggestedActionInnerTile = (
  props: SuggestedActionTileBaseProps
) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const intersection = useIntersection(elementRef, {});

  const { sendEvent } = useSegment();

  useEffect(() => {
    if (intersection?.isIntersecting) {
      sendEvent(
        TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_VIEWED,
        {
          name: props.id,
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, intersection?.isIntersecting]);

  return (
    <Box
      ref={elementRef}
      gutterH={[0, null, null, 1.5]}
      gutterV={1.5}
      layout="flex"
      bg={[Color.Transparent, null, null, Color.White]}
      borderRadius={[0, null, null, 1]}
      borderWidth={[0, null, null, 1]}
      borderColor={ColorPreset.BorderOnLight_04}
      flexDirection={["row-reverse", null, "row"]}
      height="100%"
    >
      {props.imageComponent && (
        <>
          <Visibility visible={["none", null, null, "block"]}>
            <Image
              width={48}
              height={48}
              src={props.imageComponent}
              alt={props.title?.toLocaleString() ?? ""}
            />
          </Visibility>
          <Visibility visible={["block", null, null, "none"]}>
            <Image
              width={40}
              height={40}
              src={props.imageComponent}
              alt={props.title?.toLocaleString() ?? ""}
            />
          </Visibility>
        </>
      )}
      <Space layout="inline" h={1} />
      <Box flexGrow={1}>
        <H6 spaceBelow={0.5} preset={TypePreset.Heading_02}>
          {props.title}
        </H6>
        <P preset={TypePreset.Caption_01}>{props.message}</P>
      </Box>
    </Box>
  );
};

export const SuggestedActionTile = (props: SuggestedActionProps) => {
  const router = useRouter();

  const { sendEvent } = useSegment();
  const onClickLink = () => {
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_CLICKED,
      {
        page: router.pathname,
        name: props.id,
        index: props.index,
      }
    );
  };
  const onClickButton: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_SUGGESTED_ACTION_CLICKED,
      {
        name: props.id,
        index: props.index,
      }
    );
    props.onClick?.(event);
  };

  if (typeof props.link === "string")
    return (
      <PlainLink
        target="_blank"
        href={props.link}
        css={{ width: "100%", height: "100%" }}
        effect={HoverEffect.TextDecoration}
        onClick={onClickLink}
      >
        <SuggestedActionInnerTile {...props} />
      </PlainLink>
    );
  if (typeof props.link !== "undefined")
    return (
      <LinkBuilder {...props.link} onClick={onClickLink}>
        {(result) => (
          <PlainLink
            {...result}
            css={{ width: "100%", height: "100%" }}
            effect={HoverEffect.TextDecoration}
          >
            <SuggestedActionInnerTile {...props} />
          </PlainLink>
        )}
      </LinkBuilder>
    );

  return (
    <PlainButton
      effect={HoverEffect.TextDecoration}
      title={props.id ?? ""}
      onClick={onClickButton}
      css={{ width: "100%", height: "100%" }}
    >
      <SuggestedActionInnerTile {...props} />
    </PlainButton>
  );
};
