import { ComponentType, FC, MouseEventHandler, ReactNode } from "react";
import {
  AlignItems,
  Box,
  CSSRulesFunction,
  ColorPreset,
  FontWeight,
  Text,
  TextAlign,
  useTheme,
} from "@gocardless/flux-react";
import { Route, routerPush } from "src/common/routing";
import { Trans } from "@lingui/macro";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
/* eslint-disable import/no-unresolved */
import IllustrationBookAMeeting from "src/assets/png/need-help-book-meeting@2x.png";
import IllustrationBrandYourPaymentPage from "src/assets/png/illustration-brand-your-payment-page@2x.png";
import IllustrationConnectToYourExistingSoftware from "src/assets/png/need-help-connect@2x.png";
import IllustrationCreateASandboxTestingAccount from "src/assets/png/illustration-create-a-sandbox-texting-account@2x.png";
import IllustrationSearchOurKnowledgeBase from "src/assets/png/need-help-knowledgebase@2x.png";
import IllustrationVideo from "src/assets/png/illustration-video@2x.png";
/* eslint-enable import/no-unresolved */

import { useNeedHelpCardUrls } from "./useNeedHelpCardUrls";

interface NeedHelpCardProps {
  imgSrc: string;
  header: ReactNode;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const needHelpCardStyle: CSSRulesFunction = () => ({
  cursor: "pointer",
});

const NeedHelpCard: FC<NeedHelpCardProps> & {
  BookAMeeting: ComponentType;
  BrandYourPaymentPage: ComponentType;
  ConnectToYourExistingSoftware: ComponentType;
  CreateASandboxTestingAccount: ComponentType;
  SearchOurKnowledgeBase: ComponentType;
  ViewOurDeveloperDocs: ComponentType;
  WatchOurGettingStartedVideos: ComponentType;
} = ({ imgSrc, header, onClick }) => {
  const { theme } = useTheme();

  return (
    <Box
      layout="flex"
      flexDirection="column"
      alignItems={AlignItems.Center}
      gutterH={2}
      gutterV={1}
      bg={ColorPreset.BackgroundLight_01}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_04}
      borderRadius={1}
      onClick={onClick}
      css={[needHelpCardStyle(theme)]}
      role="button"
    >
      <img src={imgSrc} alt="" width="96px" height="96px" />
      <Text
        size={3}
        weight={FontWeight.SemiBold}
        spaceAbove={0.5}
        spaceBelow={1}
        textAlign={TextAlign.Center}
        css={{ textWrap: "balance" }}
      >
        {header}
      </Text>
    </Box>
  );
};

NeedHelpCard.BookAMeeting = function BookAMeeting() {
  const { meetingURL } = useNeedHelpCardUrls();
  const { sendEventPromise } = useSegment();

  return (
    <NeedHelpCard
      imgSrc={IllustrationBookAMeeting}
      header={
        <Trans id="home.need-help.book-a-meeting">
          Book a meeting with our onboarding team
        </Trans>
      }
      onClick={async () => {
        await sendEventPromise(
          TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
          { target: "book_a_meeting" }
        );
        window.open(meetingURL);
      }}
    />
  );
};

NeedHelpCard.BrandYourPaymentPage = function BrandYourPaymentPage() {
  const { sendEventPromise } = useSegment();
  return (
    <NeedHelpCard
      imgSrc={IllustrationBrandYourPaymentPage}
      header={
        <Trans id="home.need-help.brand-your-payment-page">
          Brand your payment page
        </Trans>
      }
      onClick={async () => {
        await sendEventPromise(
          TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
          {
            target: "brand_your_experience",
          }
        );
        routerPush({
          route: Route.Brand,
        });
      }}
    />
  );
};

NeedHelpCard.ConnectToYourExistingSoftware =
  function ConnectToYourExistingSoftware() {
    const { sendEventPromise } = useSegment();

    return (
      <NeedHelpCard
        imgSrc={IllustrationConnectToYourExistingSoftware}
        header={
          <Trans id="home.need-help.automate-payments">
            Automate payments with your software
          </Trans>
        }
        onClick={async () => {
          await sendEventPromise(
            TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
            { target: "connect_to_your_existing_software" }
          );
          routerPush({
            route: Route.WelcomeUsageSelection,
          });
        }}
      />
    );
  };

NeedHelpCard.CreateASandboxTestingAccount =
  function CreateASandboxTestingAccount() {
    const { sendEventPromise } = useSegment();

    return (
      <NeedHelpCard
        imgSrc={IllustrationCreateASandboxTestingAccount}
        header={
          <Trans id="home.need-help.create-a-sandbox-testing-account">
            Create a sandbox testing account
          </Trans>
        }
        onClick={async () => {
          await sendEventPromise(
            TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
            { target: "create_a_sandbox_testing_account" }
          );
          window.open("https://manage-sandbox.gocardless.com/sign-up");
        }}
      />
    );
  };

NeedHelpCard.SearchOurKnowledgeBase = function SearchOurKnowledgeBase() {
  const { sendEventPromise } = useSegment();

  return (
    <NeedHelpCard
      imgSrc={IllustrationSearchOurKnowledgeBase}
      header={
        <Trans id="home.need-help.search-our-knowledge-base">
          Search our knowledge base
        </Trans>
      }
      onClick={async () => {
        await sendEventPromise(
          TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
          { target: "search_our_knowledge_base" }
        );
        window.open("https://hub.gocardless.com/s/knowledge-base");
      }}
    />
  );
};

NeedHelpCard.ViewOurDeveloperDocs = function ViewOurDeveloperDocs() {
  const { sendEventPromise } = useSegment();

  return (
    <NeedHelpCard
      // this image is the same as "search our knowledge base" card
      imgSrc={IllustrationSearchOurKnowledgeBase}
      header={
        <Trans id="home.need-help.view-our-developer-docs">
          View our developer docs
        </Trans>
      }
      onClick={async () => {
        await sendEventPromise(
          TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
          { target: "view_our_developer_docs" }
        );
        window.open("https://developer.gocardless.com/api-reference/");
      }}
    />
  );
};

NeedHelpCard.WatchOurGettingStartedVideos =
  function WatchOurGettingStartedVideos() {
    const { sendEventPromise } = useSegment();

    return (
      <NeedHelpCard
        imgSrc={IllustrationVideo}
        header={<Trans>Watch our getting started videos</Trans>}
        onClick={async () => {
          await sendEventPromise(
            TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_NEED_HELP_CARD_CLICKED,
            { target: "watch_our_getting_started_videos" }
          );
          window.open("https://gocardless.wistia.com/folders/vkyyouhnvr");
        }}
      />
    );
  };

export default NeedHelpCard;
