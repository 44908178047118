import { OutboundPaymentBalanceResource } from "@gocardless/api/dashboard/types";

import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";

export const outboundPaymentBalanceAmount = (
  balance: OutboundPaymentBalanceResource | undefined,
  type: OutboundPaymentBalanceType
): number => {
  switch (type) {
    case OutboundPaymentBalanceType.Current:
      return balance?.current_balance_in_cents || 0;
    case OutboundPaymentBalanceType.Available:
      return balance?.available_funds_in_cents || 0;
    case OutboundPaymentBalanceType.Scheduled:
      return balance?.scheduled_payments_in_cents || 0;
    default:
      throw new Error(
        `Unexpected type in outboundPaymentBalanceAmount: ${type}`
      );
  }
};
