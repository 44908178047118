import { CSSRulesFunction, ColorPreset } from "@gocardless/flux-react";

export const buttonStyle: CSSRulesFunction<{ completed: boolean }> = (
  theme,
  { completed }
) =>
  completed
    ? {
        color: theme.color(ColorPreset.TextOnLight_03),
        textDecoration: "line-through",
        "&:hover": {
          color: "inherit",
        },
      }
    : {};
