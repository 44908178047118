import {
  Button,
  ButtonGroup,
  ButtonVariant,
  Dialog,
  FontWeight,
  P,
  PlainButton,
  Space,
  Text,
  TypePreset,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Route, routerPush } from "src/common/routing";
import { useEffect } from "react";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
interface ActivatePayoutsDialogProps {
  open: boolean;
  toggleDialog: () => void;
  collectionsEnabled?: boolean;
}

const ActivatePayoutsDialog: React.FC<ActivatePayoutsDialogProps> = ({
  open,
  toggleDialog,
  collectionsEnabled,
}) => {
  const { sendEvent } = useSegment();

  useEffect(() => {
    if (!open) return;
    sendEvent(TrackingEvent.ACTIVATE_PAYOUTS_DIALOG_VIEWED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      header={
        <Text preset={TypePreset.Heading_03} weight={FontWeight.SemiBold}>
          <Trans>Verify your account to activate payouts</Trans>
        </Text>
      }
      footer={
        <ButtonGroup
          arrangement={["column-center-reverse", null, null, "row-end"]}
          spacing={2}
        >
          <PlainButton
            weight={FontWeight.SemiBold}
            preset={TypePreset.Body_01}
            onClick={toggleDialog}
          >
            <Trans>Go back</Trans>
          </PlainButton>

          <Button
            variant={ButtonVariant.PrimaryOnLight}
            onClick={() => routerPush({ route: Route.Setup })}
          >
            <Trans>Continue</Trans>
          </Button>
        </ButtonGroup>
      }
    >
      <>
        <P>
          <Trans>
            To get paid directly into your bank account we&apos;ll just require
            a few more details about your business and details of the bank
            account where you&apos;d like to get paid.
          </Trans>
        </P>
        <Space v={1.5} />
        {collectionsEnabled && (
          <P>
            <Trans>
              Until payouts are enabled, payments will still be collected from
              customers, but held securely with GoCardless.
            </Trans>
          </P>
        )}
      </>
    </Dialog>
  );
};

export default ActivatePayoutsDialog;
