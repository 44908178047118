import { Trans } from "@lingui/macro";

import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";

export const OutboundPaymentBalanceCardTooltipCopy: React.FC<{
  type: OutboundPaymentBalanceType;
}> = ({ type }) => {
  switch (type) {
    case OutboundPaymentBalanceType.Current:
      return (
        <Trans>
          This value represents the total amount of money in your Outbound
          Payments account, which can only be 0 or more. Please note that this
          balance reflects the amount before any scheduled payments are
          deducted.
        </Trans>
      );
    case OutboundPaymentBalanceType.Available:
      return (
        <Trans>
          The current balance minus any scheduled payments waiting to be taken.
          This value may be negative if scheduled payments exceed the current
          account balance.
        </Trans>
      );
    case OutboundPaymentBalanceType.Scheduled:
      return (
        <Trans>
          The total value of payments scheduled to be sent. This value can
          assist in forecasting the necessary amount needed to ensure all
          payments are successfully processed.
        </Trans>
      );
    default:
      return <></>;
  }
};
