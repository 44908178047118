import { FC } from "react";
import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Dialog,
  DialogProps,
  FontWeight,
  P,
  PlainLink,
  Space,
  Text,
} from "@gocardless/flux-react";
import Image from "next/image";
import { ToTranslate } from "src/components/i18n";
import AcceptPaymentsDialogImage from "src/assets/png/setup-payments/home/keep-collecting-confidently/accept-payments-dialog@2x.png";

interface AcceptPaymentsDialog extends Pick<DialogProps, "open"> {
  onClose: () => void;
}

export const AcceptPaymentsDialog: FC<AcceptPaymentsDialog> = ({
  open,
  onClose,
}) => (
  <Dialog
    as="div"
    open={open}
    header={{
      content: (
        <Text weight={FontWeight.SemiBold}>
          <ToTranslate>Accept payments on your website</ToTranslate>
        </Text>
      ),
    }}
    footer={{
      content: (
        <ButtonGroup arrangement={["column-center", null, "row-end-reverse"]}>
          <Button
            variant={ButtonVariant.PrimaryOnLight}
            size={ButtonSize.Sm}
            layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
            onClick={onClose}
          >
            Back
          </Button>
        </ButtonGroup>
      ),
    }}
    closeAction={{
      label: "Close",
      onClose,
    }}
  >
    <Image
      src={AcceptPaymentsDialogImage}
      alt=""
      width={504}
      height={246}
      css={{ width: "100%", height: "auto" }}
    />

    <Space v={1} />

    <P>
      <ToTranslate>
        Easily add a{" "}
        <PlainLink
          textDecoration="underline"
          target="_blank"
          href="https://hub.gocardless.com/s/article/Adding-a-Pay-with-GoCardless-button-to-your-website"
        >
          Pay button to your website
        </PlainLink>{" "}
        so that customers can click and authorise a new Direct Debit to you, or
        make an instant one-off payment.
      </ToTranslate>
    </P>
  </Dialog>
);
