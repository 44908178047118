import {
  Box,
  Text,
  TypePreset,
  FontWeight,
  ColorPreset,
  Color,
  Glyph,
  AlignItems,
  JustifyContent,
  Icon,
  PlainButton,
} from "@gocardless/flux-react";
import { ReactElement } from "react";

interface CustomerCardProps {
  title: ReactElement;
  description?: ReactElement;
  icon: Glyph;
  onClick: () => void;
  testId?: string;
}

const CustomerCard: React.FC<CustomerCardProps> = ({
  title,
  description,
  icon,
  onClick,
  testId,
}) => (
  <PlainButton
    onClick={onClick}
    css={{ width: "100%" }}
    data-testid={`customer-card-${testId}`}
  >
    <Box
      bg={ColorPreset.BackgroundLight_01}
      gutterV={1}
      gutterH={1}
      borderRadius={1}
      borderColor={ColorPreset.BorderOnLight_04}
      borderWidth={1}
    >
      <Box layout="flex" flexDirection="row" alignItems={AlignItems.Center}>
        <Box
          bg={Color.Ultraviolet_50}
          minWidth="60px"
          height="60px"
          borderRadius={0.5}
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.Center}
          layout="flex"
          spaceAfter={1}
        >
          <Icon name={icon} color={Color.Ultraviolet_1100} size="14px" />
        </Box>
        <Box layout="flex" flexDirection="column">
          <Box>
            <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
              {title}
            </Text>
          </Box>
          {description && (
            <Box>
              <Text preset={TypePreset.Body_01}>{description}</Text>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  </PlainButton>
);

export default CustomerCard;
