import {
  Dialog,
  P,
  UL,
  LI,
  Color,
  Interpose,
  Space,
  TypePreset,
  Text,
  FontWeight,
} from "@gocardless/flux-react";
import { Route } from "src/common/routing";
import Image from "next/image";
import InstalmentsDialogSVG from "src/assets/svg/suggested-actions-instalments-dialog.svg";
import { Trans } from "@lingui/macro";

import { DialogBodyWrapper } from "../components/DialogBodyWrapper";
import { withChildrenTarget } from "../components/withChildrenTarget";
import { DialogCTA, DialogCTAProps } from "../components/DialogCTA";

export const TryInstalmentsBodyWrapper = () => (
  <DialogBodyWrapper
    bg={Color.Dusk_200}
    image={
      <Image
        src={InstalmentsDialogSVG}
        alt="instalment-dialog"
        width={96}
        height={96}
      />
    }
    title={<Trans>Try Instalments</Trans>}
  >
    <Interpose node={<Space v={1} />}>
      <P preset={TypePreset.Body_01}>
        <Trans>
          {`Offer your customers more flexibility in the way they pay. Instalments are a great way to spread the cost of larger payments for new and existing customers. You can create payments for a set schedule and varying amounts, and your customer won't have to lift a finger.`}
        </Trans>
      </P>
      <P>
        <Text weight={FontWeight.Bold}>
          <Trans>Instalment payments are great for:</Trans>
        </Text>
      </P>
      <UL>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>
              Spreading the cost of larger payments like an annual invoice{" "}
            </Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Charging for a block of classe</Trans>
          </Text>
        </LI>
        <LI>
          <Text preset={TypePreset.Body_01}>
            <Trans>Billing 50% up-front and 50% late</Trans>
          </Text>
        </LI>
      </UL>
    </Interpose>
  </DialogBodyWrapper>
);

export const TryInstalmentsFooter = ({
  name,
}: Pick<DialogCTAProps, "name">) => (
  <DialogCTA route={Route.InstalmentScheduleCreate} name={name} />
);

export const TryInstalments = withChildrenTarget(({ ...props }) => (
  <>
    <Dialog {...props} footer={<TryInstalmentsFooter name={props.id} />}>
      <TryInstalmentsBodyWrapper />
    </Dialog>
  </>
));
