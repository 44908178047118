import { useSubscriptionList } from "@gocardless/api/dashboard/subscription";
import { SubscriptionsStatus } from "@gocardless/api/dashboard/types";
import { Plural } from "@lingui/macro";
import { InstancesIncludeTotalCount } from "src/utils/types";

export const SubscriptionCount: React.FC<{ planId: string }> = ({ planId }) => {
  const { data: subscriptionsResponse } = useSubscriptionList({
    include_total_count: InstancesIncludeTotalCount.True,
    limit: 1,
    plan: planId,
    status: SubscriptionsStatus.Active,
  });
  return (
    <Plural
      id="homepage.quickactions.subscription-customers"
      value={subscriptionsResponse?.meta?.total_count ?? 0}
      one="# active customer"
      other="# active customers"
      zero="No active customers"
    />
  );
};
