import { FC } from "react";
import { DialogProps } from "@gocardless/flux-react";

import { KccChecklistDialog } from "./KccChecklistDialog";
import { useKccChecklistDialog } from "./useKccChecklistDialog";

interface KccChecklistDialogContainerProps extends Pick<DialogProps, "open"> {
  toggleDialog: () => void;
  openCreatePaymentDialog: () => void;
}

export const KccChecklistDialogContainer: FC<
  KccChecklistDialogContainerProps
> = ({ open, toggleDialog, openCreatePaymentDialog }) => {
  const { stepsCompleted, markStepCompleted, hasMandates } =
    useKccChecklistDialog();

  return (
    <KccChecklistDialog
      open={open}
      toggleDialog={toggleDialog}
      openCreatePaymentDialog={openCreatePaymentDialog}
      stepsCompleted={stepsCompleted}
      markStepCompleted={markStepCompleted}
      hasMandates={hasMandates}
    />
  );
};
