import { Include2020_TaxCutover } from "@gocardless/api/dashboard/types";

export type NonEmptyArray<T> = [T, ...T[]];

export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr.length > 0;
}

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}

export function assertUnreachable(x: never): never {
  throw new Error(`Unexpected type ${x}`);
}

// Yes, this is really gross. We need to use an alias here because the library
// which generates our TypeScript types from the JSON schema (quicktype) applies
// some rather dubious optimizations to types with the same signature, meaning
// they are collapsed into one definition. Since InstancesIncludeTotalCount and
// Include2020_TaxCutover share the same type (an enum with True and False
// values), quicktype is collapsing all enums with this signature into the type
// Include2020_TaxCutover. This is really confusing as we end up having to use
// Include2020_TaxCutover in code totally unrelated to tax cutovers. We have
// investigated removing this optimization from quicktype, but it does not seem
// to be possible currently. This alias aims to keep the use of enums in the
// code intuitive in the short term, but in the long term, we are likely to have
// to look for an alternative to quicktype.
//
// For more context on this issue see https://github.com/gocardless/api/issues/64
export const InstancesIncludeTotalCount = Include2020_TaxCutover;
