import { useRouter } from "next-router-mock";
import { useCallback } from "react";
import { useJourneyCompletedEvent } from "src/common/hooks/useJourneyCompletedEvent/useJourneyCompletedEvent";
import { SharingMethod } from "src/common/trackingEventAttributes";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { copyToClipboard } from "src/utils/copyToClipboard";

interface ShareWithEventsHookConfig {
  dedupedEvent: TrackingEvent;
  event: TrackingEvent;
}
export const useShareWithEvents = ({
  dedupedEvent,
  event,
}: ShareWithEventsHookConfig) => {
  const router = useRouter();
  const { sendEvent } = useSegment();

  const { onJourneyComplete } = useJourneyCompletedEvent(dedupedEvent);

  return useCallback(
    (url?: string) => {
      copyToClipboard(url);
      onJourneyComplete(SharingMethod.LINK_COPIED);
      sendEvent(event, {
        page: router.pathname,
        sharing_method: SharingMethod.LINK_COPIED,
      });
    },
    [event, onJourneyComplete, router.pathname, sendEvent]
  );
};
