import { CSSRulesFunction, useTheme } from "@gocardless/flux-react";

export const useMenuStyle = () => {
  const { theme } = useTheme();

  const menuStyle = {
    "[data-field-control]": {
      margin: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    },
  };

  return menuStyle;
};

export const chartWidgetContainerStyle: CSSRulesFunction = (theme) => [
  theme.responsive([`-${theme.spacing(1)}`, theme.spacing(0)], (value) => ({
    marginTop: value,
  })),
];

export const mobileSeparatorStyle: CSSRulesFunction = (theme) => [
  theme.responsive(["block", "none"], (value) => ({
    display: value,
  })),
  {
    margin: `${theme.spacing(1)} 0`,
    ":last-of-type": {
      display: "none",
    },
  },
];

export const betaWidgetContainerLargeBreakpointStyle: CSSRulesFunction = (
  theme
) => [
  theme.responsive(["none", null, null, "flex"], (value) => ({
    display: value,
  })),
];

export const betaWidgetContainerMediumBreakpointStyle: CSSRulesFunction = (
  theme
) => [
  theme.responsive(["none", "flex", null, "none"], (value) => ({
    display: value,
  })),
];

export const betaWidgetContainerSmallBreakpointStyle: CSSRulesFunction = (
  theme
) => [
  theme.responsive(["flex", "none"], (value) => ({
    display: value,
  })),
];
