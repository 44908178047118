import { FC } from "react";
import {
  Box,
  CSSRulesFunction,
  ColorPreset,
  ColorProp,
  FontWeight,
  Text,
  TypePreset,
  useTheme,
} from "@gocardless/flux-react";

interface ProgressCircleProps {
  value: number;
  max: number;
  fill?: ColorProp;
  track?: ColorProp;
  bg?: ColorProp;
  text?: ColorProp;

  ariaLabelledby?: string;
}

const progressCircleStyle: CSSRulesFunction<ProgressCircleProps> = (
  theme,
  {
    value,
    max,
    fill = ColorPreset.DataOnLight_02,
    track = ColorPreset.BorderOnLight_04,
    bg = ColorPreset.BackgroundLight_02,
  }
) => {
  const percentage = value / max;

  const fillColor = theme.color(fill),
    trackColor = theme.color(track),
    bgColor = theme.color(bg);

  return {
    display: "grid",
    placeItems: "center",
    position: "relative",
    borderRadius: "50%",
    background: `
      radial-gradient(closest-side, ${bgColor} calc(100% - 6px), transparent 0 99.9%, transparent 0),
      conic-gradient(${fillColor} calc(${percentage} * 100%), ${trackColor} 0)
    `,
  };
};

const startCapStyle: CSSRulesFunction<ProgressCircleProps> = (
  theme,
  { fill = ColorPreset.DataOnLight_02 }
) => ({
  position: "absolute",
  top: "0",
  left: "45px",
  width: "6px",
  height: "6px",
  borderRadius: "50%",
  backgroundColor: theme.color(fill),
});

const endCapStyle: CSSRulesFunction<ProgressCircleProps> = (theme, props) => {
  const { value, max } = props;
  const percentage = value / max;

  return [
    startCapStyle(theme, props),
    {
      // rotate around center of circle
      transform: `rotate(${percentage}turn)`,
      transformOrigin: "3px 48px",
    },
  ];
};

const ProgressCircle: FC<ProgressCircleProps> = (props) => {
  const { value, max, ariaLabelledby = "progress-circle-label", text } = props;
  const { theme } = useTheme();

  return (
    <Box
      flexShrink={0}
      width="96px"
      height="96px"
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={max}
      aria-labelledby={ariaLabelledby}
      css={[progressCircleStyle(theme, props)]}
    >
      <div css={[startCapStyle(theme, props)]} />
      <div css={[endCapStyle(theme, props)]} />

      <Text
        preset={TypePreset.Body_04}
        weight={FontWeight.SemiBold}
        color={text}
      >
        {value < 4 ? value : value}/{max}
      </Text>
    </Box>
  );
};

export default ProgressCircle;
