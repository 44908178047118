import {
  H2,
  TextAlign,
  TypePreset,
  TypographyStyleProps,
} from "@gocardless/flux-react";

export const HomeSectionHeader: React.FC<
  {
    children: React.ReactNode;
  } & TypographyStyleProps
> = (props) => (
  <H2 preset={TypePreset.Heading_04} textAlign={TextAlign.Left} {...props} />
);
