export enum FundCategoryType {
  PENDING = "Pending",
  COLLECTED = "Collected",
  PAYOUT = "Payout",
  UPCOMING = "Upcoming",
}

export enum BalanceType {
  HomeBalance = "home-balance",
  FXBalance = "fx-balance",
  DomesticBalance = "domestic-balance",
}
