import { TrackingEvent } from "src/common/trackingEvents";
import { SharingMethod } from "src/common/trackingEventAttributes";
import { useState } from "react";

import { useTrackShareEvent } from "../../../hooks/useTrackShareEvent";

/**
 * Keeps track of journey completed events for a session, only firing them if
 * one has not been fired before.
 */
export const useJourneyCompletedEvent = (
  eventName: TrackingEvent
): {
  onJourneyComplete: (
    sharingMethod: SharingMethod,
    extraAttributes?: object
  ) => Promise<void>;
} => {
  const [hasShared, setHasShared] = useState(false);

  const { trackShareEvent } = useTrackShareEvent(eventName);

  const onJourneyComplete = async (
    sharingMethod: SharingMethod,
    extraAttributes: object = {}
  ): Promise<void> => {
    if (!hasShared) {
      await trackShareEvent(sharingMethod, {
        ...extraAttributes,
      });
      setHasShared(true);
    }
  };

  return {
    onJourneyComplete,
  };
};
