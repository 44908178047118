import { FC, MouseEventHandler, useEffect, useState } from "react";
import {
  ColorScheme,
  FontWeight,
  Interpose,
  Space,
  Tag,
  TagColor,
  Text,
  Box,
  AlignItems,
  Visibility,
  ColorPreset,
  Separator,
  ButtonVariant,
  ButtonSize,
  TextAlign,
  PlainButton,
  Button,
  ButtonLayout,
  useTheme,
  Color,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { Route, routerPush } from "src/common/routing";
import { CreditorsVerificationStatus } from "@gocardless/api/dashboard/types";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
/* eslint-disable import/no-unresolved */
import IllustrationCollectFirstPayment from "src/assets/png/tasklist-collect-first-payment@2x.png";
import IllustrationEnableTwoStepAuthentication from "src/assets/png/tasklist-enable-2fa@2x.png";
import IllustrationCreateAccount from "src/assets/png/tasklist-create-account@2x.png";
import IllustrationKeepCollecting from "src/assets/png/tasklist-keep-collecting@2x.png";
import IllustrationGetstartedWithPayments from "src/assets/png/tasklist-get-started-with-payments@2x.png";
import IllustrationSetupAccount from "src/assets/png/tasklist-set-up-account@2x.png";
/* eslint-enable import/no-unresolved */
import { AccountSetupVersion } from "src/common/trackingEventAttributes";
import { parseAsBoolean, useQueryState } from "nuqs";
import StepCard from "src/components/ui/StepCard/StepCard";
import ProgressCircle from "src/components/ui/ProgressCircle/ProgressCircle";
import { RewardsModal } from "src/components/rewards-modal/RewardsModal";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

import { useCollectionsPermitted } from "../common/hooks/useCollectionsPermitted";
import { LowRiskOnboardingVariant } from "../common/type";

import { UseAccountSetup } from "./useAccountSetup";
import AccountSetupStep from "./AccountSetupStep";
import FirstPayoutDialog from "./FirstPayout/FirstPayoutDialog";
import AccountSetupCompleted from "./AccountSetupCompleted/AccountSetupCompleted";
import FrequentActions from "./FrequentActions/FrequentActions";
import CreatePaymentDialog from "./CreatePayment/CreatePaymentDialog";
import PaymentFromCustomerDialog from "./CreatePayment/PaymentFromCustomerDialog";
import { CollectionsEnabledDialog } from "./CollectionsEnabled/CollectionsEnabledDialog";
import CollectedPayments from "./CollectedPayments/CollectedPayments";
import ActivatePayoutsDialog from "./ActivatePayouts/ActivatePayoutsDialog";
import { useStepCardVariant } from "./useStepCardVariant";
import { KccChecklistDialogContainer } from "./KccChecklistDialog/KccChecklistDialogContainer";
import { separatorStyle } from "./AccountSetup.styles";
import { RewardCollectionsEnabledDialog } from "./CollectionsEnabled/RewardCollectionsEnabledDialog";

interface AccountSetupStepComponent extends FC<{ index: number }> {}

const AccountSetup: FC<UseAccountSetup> = ({
  steps,
  loaded,
  value,
  max,
  firstPayoutVariant,
  hasCustomer,
  user,
  completed,
  organisationName,
  hasCloseGreatWorkBanner,
  closeBanner,
  hasDiscountExtension,
  canExtendDiscount,
  status,
  lowRiskOnboardingVariant,
  useRevampedLayout,
}) => {
  const [focusedStepCard, setFocusedStepCard] =
    useState<AccountSetupStep | null>();

  const [ready, setReady] = useState(false);
  const [openDialog, setOpenDialog] = useState<
    | "first-payout"
    | "create-payment"
    | "payment-from-customer"
    | "activate-payouts"
    | "kcc-checklist"
    | "rewards"
    | null
  >(null);

  const { isVariationOn: isRewardsEnabled } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_REWARDS_AND_BOOSTS,
  });

  const variant = useStepCardVariant({
    steps,
    focusedStepCard,
    status,
    useRevampedLayout,
  });

  const [collectionsEnabledDialog] = useQueryState(
    "collections_enabled_dialog",
    parseAsBoolean
  );
  const [rewardCollectionsEnabledDialog] = useQueryState(
    "reward_collections_enabled_dialog",
    parseAsBoolean
  );

  const lowRiskOnboardingDisabled =
    lowRiskOnboardingVariant === LowRiskOnboardingVariant.Disabled;

  const accountSetupVersion = lowRiskOnboardingDisabled
    ? AccountSetupVersion.FIRST_PAYMENT_JOURNEY
    : AccountSetupVersion.LOW_RISK_ONBOARDING;

  const { theme } = useTheme();
  const { sendEvent } = useSegment();
  const { collectionsEnabled, collectionsEnabledTrackingAttribute } =
    useCollectionsPermitted();

  useEffect(() => {
    if (loaded) {
      const nextStep = findNextStep();

      if (nextStep !== null) {
        setFocusedStepCard(nextStep);
      }

      setReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, steps]);

  useEffect(() => {
    if (!loaded) return;

    sendEvent(TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_PAGE_VIEWED, {
      page: window.location.pathname,
      page_version: "variant",
      is_complete: completed,
      verification_status: status,
      version: accountSetupVersion,
      ...collectionsEnabledTrackingAttribute,
    });
    // TODO: Fix exhaustive dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  if (!ready) return null;

  function findNextStep() {
    // determine the first incomplete account setup step, to make its step card focused
    const nextIndex = Object.values(steps).findIndex((v) => v === false);
    if (nextIndex > -1) {
      return Object.keys(steps)[nextIndex] as AccountSetupStep; // index type erased
    }

    return null;
  }

  const handleBoxClick: (
    step: AccountSetupStep
  ) => MouseEventHandler<HTMLButtonElement> = (step) => () => {
    sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_FOCUSED, {
      target: step,
      version: accountSetupVersion,
    });
    setFocusedStepCard(step);
  };

  const alreadyExtendedTag = (
    <Tag colorScheme={ColorScheme.OnDark} color={TagColor.Primary}>
      <Trans id="home.step-card.get-your-first-payout.extend-discount-done">
        You earned +30 days without fees
      </Trans>
    </Tag>
  );

  const canExtendTag = (
    <Tag
      colorScheme={ColorScheme.OnDark}
      color={useRevampedLayout ? TagColor.Success : TagColor.Primary}
      css={
        isRewardsEnabled
          ? {
              "--tag-color": theme.color(Color.Ultraviolet_1400),
              "--tag-bg": theme.color(Color.Ultraviolet_50),
            }
          : {}
      }
    >
      {useRevampedLayout ? (
        <Trans>+ Extend your no-fee bonus</Trans>
      ) : (
        <Trans id="home.step-card.get-your-first-payout.extend-discount">
          + Extend your no-fee period
        </Trans>
      )}
    </Tag>
  );

  const extendDiscountTag = hasDiscountExtension
    ? alreadyExtendedTag
    : canExtendDiscount
      ? canExtendTag
      : null;

  const SetUpYourAccountStep: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.SetUpYourAccount)}
      index={index}
      imgSrc={IllustrationSetupAccount}
      header={
        status === CreditorsVerificationStatus.InReview ? (
          <Trans id="home.step-card.verifying-your-account">
            We&apos;re verifying your account
          </Trans>
        ) : (
          <Trans id="home.step-card.set-up-your-account">
            Set up your account
          </Trans>
        )
      }
      onBoxClick={handleBoxClick(AccountSetupStep.SetUpYourAccount)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        {status === CreditorsVerificationStatus.InReview ? (
          <Trans id="home.step-card.set-up-your-account-in-review-body">
            It usually takes us 1 - 3 days to verify an account and we will
            email you once this is complete
          </Trans>
        ) : (
          <Trans id="home.step-card.set-up-your-account-body">
            Add your business details so we can verify your account
          </Trans>
        )}
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          routerPush({
            route:
              status === CreditorsVerificationStatus.InReview
                ? Route.AccountStatus
                : Route.Setup,
          });

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.SetUpYourAccount,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        {status === CreditorsVerificationStatus.InReview ? (
          <Trans id="home.step-card.set-up-your-accountaccount-status-btn">
            Account status
          </Trans>
        ) : (
          <Trans id="Continue">Continue</Trans>
        )}
      </Button>
    </StepCard>
  );

  const GetStartedWithPaymentsStep: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.GetStartedWithPayments)}
      index={index}
      imgSrc={IllustrationGetstartedWithPayments}
      header={
        <Trans id="home.step-card.get-started-with-payments">
          Get started with payments
        </Trans>
      }
      onBoxClick={handleBoxClick(AccountSetupStep.GetStartedWithPayments)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        <Trans id="home.step-card.get-started-with-payments-body">
          Set up your first product or service to see how it works
        </Trans>
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          routerPush({
            route: Route.SetupPayments,
          });

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.SetUpYourAccount,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        {<Trans id="Continue">Continue</Trans>}
      </Button>
    </StepCard>
  );

  const GetYourFirstPayoutStep: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.GetYourFirstPayout)}
      index={index}
      imgSrc={IllustrationCollectFirstPayment}
      header={
        <Trans id="home.step-card.get-your-first-payout">
          Get your first payout
        </Trans>
      }
      tag={extendDiscountTag}
      onBoxClick={handleBoxClick(AccountSetupStep.GetYourFirstPayout)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        {
          <>
            <Trans id="home.step-card.get-your-first-payout-body">
              Receive a first payment into your bank account
            </Trans>
          </>
        }
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={() => {
          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.GetYourFirstPayout,
            verification_status: status,
            version: accountSetupVersion,
          });

          setOpenDialog("first-payout");
        }}
      >
        {<Trans id="Continue">Continue</Trans>}
      </Button>
    </StepCard>
  );

  const EnableTwoStepAuthenticationStep: AccountSetupStepComponent = ({
    index,
  }) => (
    <StepCard
      variant={variant(AccountSetupStep.EnableTwoStepAuthentication)}
      index={index}
      imgSrc={IllustrationEnableTwoStepAuthentication}
      header={
        <Trans id="home.step-card.enable-two-step-authentication">
          Enable two-step authentication
        </Trans>
      }
      onBoxClick={handleBoxClick(AccountSetupStep.EnableTwoStepAuthentication)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        <Trans id="home.step-card.enable-two-step-authentication-body">
          Add an extra layer of account security
        </Trans>
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          routerPush({
            route: Route.MFASetup,
          });

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.EnableTwoStepAuthentication,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        {<Trans id="Continue">Continue</Trans>}
      </Button>
    </StepCard>
  );

  const CreateYourAccountStep: AccountSetupStepComponent = ({ index }) => {
    const nextStep = findNextStep();

    return (
      <StepCard
        variant={variant(AccountSetupStep.CreateYourAccount)}
        index={index}
        imgSrc={IllustrationCreateAccount}
        header={<Trans>Create your account</Trans>}
        onBoxClick={handleBoxClick(AccountSetupStep.CreateYourAccount)}
      >
        <Text
          size={2}
          color={ColorPreset.TextOnLight_03}
          textAlign={[TextAlign.Center, null, TextAlign.Left]}
          spaceBelow={1}
        >
          {<Trans>You&apos;re ready to start using GoCardless</Trans>}
        </Text>

        <Button
          variant={ButtonVariant.PrimaryOnLight}
          layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
          onClick={async () => {
            nextStep
              ? setFocusedStepCard(nextStep)
              : setFocusedStepCard(AccountSetupStep.GetStartedWithPayments);

            sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
              target: AccountSetupStep.CreateYourAccount,
              verification_status: status,
              version: accountSetupVersion,
            });
          }}
        >
          {<Trans>Next</Trans>}
        </Button>
      </StepCard>
    );
  };

  const CollectYourFirstPayment: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.CollectYourFirstPayment)}
      index={index}
      imgSrc={IllustrationCollectFirstPayment}
      header={<Trans>Collect your first payment</Trans>}
      tag={extendDiscountTag}
      onBoxClick={handleBoxClick(AccountSetupStep.CollectYourFirstPayment)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        <Trans>Take a first payment or take a test payment from yourself</Trans>
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          setOpenDialog("first-payout");

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.CollectYourFirstPayment,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        <Trans>Continue</Trans>
      </Button>
    </StepCard>
  );

  const GetPaidEffortlessly: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.GetPaidEffortlessly)}
      index={index}
      imgSrc={IllustrationKeepCollecting}
      header={<Trans>Get paid effortlessly</Trans>}
      onBoxClick={handleBoxClick(AccountSetupStep.GetPaidEffortlessly)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        <Trans>
          Collect your first three payments to see how easy it is to get paid on
          time
        </Trans>
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          setOpenDialog("create-payment");

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.GetPaidEffortlessly,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        <Trans>Continue</Trans>
      </Button>
    </StepCard>
  );

  const KccChecklist: AccountSetupStepComponent = ({ index }) => (
    <StepCard
      variant={variant(AccountSetupStep.KccChecklist)}
      index={index}
      imgSrc={IllustrationKeepCollecting}
      header={<Trans>Get paid effortlessly</Trans>}
      onBoxClick={handleBoxClick(AccountSetupStep.KccChecklist)}
    >
      <Text
        size={2}
        color={ColorPreset.TextOnLight_03}
        textAlign={[TextAlign.Center, null, TextAlign.Left]}
        spaceBelow={1}
      >
        <Trans>
          Finish your setup to get fully up and running with GoCardless
        </Trans>
      </Text>

      <Button
        variant={ButtonVariant.PrimaryOnLight}
        layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
        onClick={async () => {
          setOpenDialog("kcc-checklist");

          sendEvent(TrackingEvent.GET_STARTED_STEP_CARD_CTA_CLICKED, {
            target: AccountSetupStep.KccChecklist,
            verification_status: status,
            version: accountSetupVersion,
          });
        }}
      >
        <Trans>Get started</Trans>
      </Button>
    </StepCard>
  );

  const componentsMap: Record<string, AccountSetupStepComponent> = {
    [AccountSetupStep.CreateYourAccount]: CreateYourAccountStep,
    [AccountSetupStep.SetUpYourAccount]: SetUpYourAccountStep,
    [AccountSetupStep.GetStartedWithPayments]: GetStartedWithPaymentsStep,
    [AccountSetupStep.CollectYourFirstPayment]: CollectYourFirstPayment,
    [AccountSetupStep.GetYourFirstPayout]: GetYourFirstPayoutStep,
    [AccountSetupStep.EnableTwoStepAuthentication]:
      EnableTwoStepAuthenticationStep,
    [AccountSetupStep.GetPaidEffortlessly]: GetPaidEffortlessly,
    [AccountSetupStep.KccChecklist]: KccChecklist,
  };

  const accountSetupStepsControl = (
    <Box gutterV={[1, null, 3]}>
      <Box
        layout="flex"
        flexDirection="row"
        alignItems={AlignItems.Center}
        spaceBelow={[2, null, 3]}
      >
        <ProgressCircle value={value} max={max} />

        <Space layout="inline" h={[1, null, 2]} />

        <Text
          size={[5, null, 7, 8]}
          weight={FontWeight.SemiBold}
          textAlign={TextAlign.Left}
        >
          {user?.given_name ? (
            <Trans id="home.lets-get-you-up-and-running">
              {user.given_name}, let&apos;s get you up and running
            </Trans>
          ) : (
            <Trans id="home.no-username-lets-get-you-up-and-running">
              Let&apos;s get you up and running
            </Trans>
          )}
        </Text>
      </Box>

      <Interpose node={<Space v={1} />}>
        {Object.keys(steps).map((step, index) => {
          const order = index + 1;
          const DynamicComponent = componentsMap[step];
          if (!DynamicComponent) return null;
          return <DynamicComponent key={order} index={order} />;
        })}
      </Interpose>

      <Visibility visible={["block", null, null, "none"]}>
        <Space v={[2, null, 3]} />
        <Text size={[5, null, 7]} weight={FontWeight.SemiBold}>
          <Trans id="home.frequent-actions">Frequent actions</Trans>
        </Text>
        <Space v={2} />
        <FrequentActions />
      </Visibility>
    </Box>
  );

  const onActivatePayoutsClick: MouseEventHandler<HTMLButtonElement> = () => {
    setOpenDialog("activate-payouts");

    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_ACTIVATE_PAYOUTS_CLICKED,
      collectionsEnabledTrackingAttribute
    );
  };

  const onViewPaymentsClick: MouseEventHandler<HTMLButtonElement> = () => {
    routerPush({ route: Route.Payments });

    // this is expected to be the same event as onActivatePayoutsClick
    sendEvent(
      TrackingEvent.MERCHANT_DASHBOARD_HOMEPAGE_ACTIVATE_PAYOUTS_CLICKED,
      collectionsEnabledTrackingAttribute
    );
  };

  const accountSetupStepsVariant = (
    <Box gutterV={[null, null, 2]} spaceBelow={2}>
      <Box
        gutterV={[1.5, null, 3, null, 4]}
        gutterH={[1.5, null, 3, null, 4]}
        bg={ColorPreset.BackgroundLight_01}
        borderWidth={1}
        borderRadius={1.5}
        borderColor={ColorPreset.BorderOnLight_04}
      >
        <Box
          layout="flex"
          flexDirection="row"
          alignItems={AlignItems.Center}
          spaceBelow={[1, null, 2]}
        >
          <ProgressCircle
            value={value}
            max={max}
            bg={ColorPreset.BackgroundLight_01}
          />

          <Space layout="inline" h={[1, null, 2]} />

          <Text size={[5, null, 7]} weight={FontWeight.SemiBold}>
            {user?.given_name ? (
              <Trans id="home.lets-get-you-up-and-running">
                {user.given_name}, let&apos;s get you up and running
              </Trans>
            ) : (
              <Trans id="home.no-username-lets-get-you-up-and-running">
                Let&apos;s get you up and running
              </Trans>
            )}
          </Text>
        </Box>

        <Box layout="flex" flexDirection={["column", null, null, "row"]}>
          <Box width="100%" spaceBelow={2} spaceAbove={1.5}>
            <Interpose
              node={(_, index) => {
                // Insert a taller Separator following two step cards that show the extend discount tag,
                // because when they are not focused the tag wraps onto a new line and causes a weird gap
                const stepCard = Object.keys(steps)[index] as AccountSetupStep;
                const tallSeparator = (() => {
                  if (!extendDiscountTag) return false;
                  if (focusedStepCard === stepCard) return false;
                  if (
                    stepCard !== AccountSetupStep.GetYourFirstPayout &&
                    stepCard !== AccountSetupStep.CollectYourFirstPayment
                  )
                    return false;
                  return true;
                })();

                return (
                  <Box>
                    <Space layout="inline" h={[2, 3]} />
                    <Separator
                      direction="inline-vertical"
                      color={ColorPreset.BorderOnLight_03}
                      css={[separatorStyle(theme, { tallSeparator })]}
                    />
                  </Box>
                );
              }}
            >
              {Object.keys(steps).map((step, index) => {
                const order = index + 1;
                const DynamicComponent = componentsMap[step];
                if (!DynamicComponent) return null;
                return <DynamicComponent key={order} index={order} />;
              })}
            </Interpose>
          </Box>

          <Visibility visible={["none", null, null, "block"]}>
            <Box layout="flex" height="100%">
              <Separator
                direction="inline-vertical"
                spacing={[[0, 3]]}
                color={ColorPreset.BorderOnLight_05}
              />
              <Box gutterV={2}>
                <CollectedPayments status={status} />
                <Space v={1.5} />

                {status === CreditorsVerificationStatus.Successful ? (
                  <PlainButton
                    decoration="underline"
                    weight={FontWeight.SemiBold}
                    size={2}
                    onClick={onViewPaymentsClick}
                  >
                    <Trans id="get-your-first-payout.view-payments-button">
                      View payments
                    </Trans>
                  </PlainButton>
                ) : (
                  <PlainButton
                    decoration="underline"
                    weight={FontWeight.SemiBold}
                    size={2}
                    onClick={onActivatePayoutsClick}
                  >
                    <Trans>Activate payouts</Trans>
                  </PlainButton>
                )}
              </Box>
            </Box>
          </Visibility>
        </Box>
      </Box>

      <Visibility visible={["block", null, null, "none"]}>
        <Space v={1} />
        <Box
          gutterV={2}
          gutterH={2}
          bg={ColorPreset.BackgroundLight_01}
          borderWidth={1}
          borderRadius={1}
          borderColor={ColorPreset.BorderOnLight_04}
        >
          <CollectedPayments status={status} />

          <Space v={1.5} />

          {status === CreditorsVerificationStatus.Successful ? (
            <Button
              type="button"
              variant={ButtonVariant.SecondaryOnLight}
              size={ButtonSize.Sm}
              onClick={onViewPaymentsClick}
            >
              <Trans id="get-your-first-payout.view-payments-button">
                View payments
              </Trans>
            </Button>
          ) : (
            <Button
              type="button"
              variant={ButtonVariant.SecondaryOnLight}
              size={ButtonSize.Sm}
              onClick={onActivatePayoutsClick}
            >
              <Trans>Activate payouts</Trans>
            </Button>
          )}
        </Box>
      </Visibility>

      <Visibility visible={["block", null, null, "none"]}>
        <Space v={[2, null, 3]} />
        <Text size={[5, null, 7]} weight={FontWeight.SemiBold}>
          <Trans id="home.frequent-actions">Frequent actions</Trans>
        </Text>
        <Space v={2} />
        <FrequentActions />
      </Visibility>
    </Box>
  );

  const accountSetupSteps = useRevampedLayout
    ? accountSetupStepsVariant
    : accountSetupStepsControl;

  return (
    <>
      {completed ? (
        <AccountSetupCompleted
          organisationName={organisationName}
          hasClosedBanner={hasCloseGreatWorkBanner}
          closeBanner={closeBanner}
          hasDiscountExtension={hasDiscountExtension}
          useRevampedLayout={useRevampedLayout}
          status={status}
          collectionsEnabled={collectionsEnabled}
        />
      ) : (
        accountSetupSteps
      )}

      <FirstPayoutDialog
        variant={firstPayoutVariant}
        open={openDialog === "first-payout"}
        toggleDialog={() => setOpenDialog(null)}
        hasCustomer={hasCustomer}
        canExtendDiscount={canExtendDiscount}
        appName=""
        openCreatePaymentDialog={() => setOpenDialog("create-payment")}
        openRewardsDialog={() => setOpenDialog("rewards")}
        collectionsEnabled={collectionsEnabled}
      />

      <CreatePaymentDialog
        open={openDialog === "create-payment"}
        toggleDialog={() => {
          if (
            focusedStepCard === AccountSetupStep.GetPaidEffortlessly ||
            focusedStepCard === AccountSetupStep.KccChecklist ||
            focusedStepCard === AccountSetupStep.GetYourFirstPayout
          ) {
            setOpenDialog(null);
          } else {
            setOpenDialog("first-payout");
          }
        }}
        openPaymentFromCustomerDialog={() =>
          setOpenDialog("payment-from-customer")
        }
      />

      <PaymentFromCustomerDialog
        open={openDialog === "payment-from-customer"}
        toggleDialog={() => setOpenDialog("create-payment")}
      />

      {collectionsEnabledDialog ? <CollectionsEnabledDialog /> : null}
      {rewardCollectionsEnabledDialog ? (
        <RewardCollectionsEnabledDialog />
      ) : null}

      <ActivatePayoutsDialog
        open={openDialog === "activate-payouts"}
        toggleDialog={() => setOpenDialog(null)}
        collectionsEnabled={collectionsEnabled}
      />

      <KccChecklistDialogContainer
        open={openDialog === "kcc-checklist"}
        toggleDialog={() => setOpenDialog(null)}
        openCreatePaymentDialog={() => setOpenDialog("create-payment")}
      />

      <RewardsModal
        open={openDialog === "rewards"}
        onClose={() => setOpenDialog(null)}
      />
    </>
  );
};

export default AccountSetup;
