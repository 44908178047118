import { css, Interpolation, keyframes } from "@emotion/react";
import {
  Color,
  CSSRulesFunction,
  FontWeight,
  Theme,
} from "@gocardless/flux-react";
import OdometerAnimationShape1 from "src/assets/png/odometer-animation-shape-1@2x.png";
import OdometerAnimationShape2 from "src/assets/png/odometer-animation-shape-2@2x.png";

const shape1 = keyframes`
  from, to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-12px);
  }
`;

const shape2 = keyframes`
  from, to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15px);
  }
`;

export const boxStyle: CSSRulesFunction = () => ({
  position: "relative",
  height: "120px",
  "&:before": {
    content: "''",
    position: "absolute",
    top: "0",
    left: "-21px",
    width: "45px",
    height: "45px",
    backgroundImage: `url('${OdometerAnimationShape1}')`,
    backgroundSize: "45px 45px",
    animation: `${shape1} 6s ease-in-out infinite`,
  },
  "&:after": {
    content: "''",
    position: "absolute",
    bottom: "0",
    right: "-18px",
    width: "43px",
    height: "42px",
    backgroundImage: `url('${OdometerAnimationShape2}')`,
    backgroundSize: "43px 42px",
    animation: `${shape2} 5s ease-in-out infinite`,
  },
  "@media (prefers-reduced-motion)": {
    "&:before": {
      animation: "none",
    },
    "&:after": {
      animation: "none",
    },
  },
});

/* based on css from https://github.hubspot.com/odometer/api/themes/ */
export const odometerThemeStyle: (theme: Theme) => Interpolation<{}> = (
  theme
) => css`
  .odometer.odometer-auto-theme {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 150px;
    text-align: center;
  }
  .odometer.odometer-auto-theme .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: -2px;
    bottom: 0;
    overflow: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon {
    display: block;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value {
    display: block;
  }
  .odometer.odometer-auto-theme
    .odometer-digit
    .odometer-value.odometer-last-value {
    position: absolute;
  }
  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner {
    transition: transform 1.5s;
  }
  .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
    .odometer-ribbon-inner {
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner {
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
    .odometer-ribbon-inner {
    transition: transform 1.5s;
    transform: translateY(0);
  }
  .odometer.odometer-auto-theme {
    position: relative;
    background-size: 100%;
    background-color: ${theme.color(Color.Ultraviolet_700)};
    padding: 0 24px;
    border-radius: 24px;
  }
  .odometer.odometer-auto-theme::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      ${theme.color(Color.Ultraviolet_700)}80 0%,
      ${theme.color(Color.White)}00 25%,
      ${theme.color(Color.White)}00 75%,
      ${theme.color(Color.Ultraviolet_700)}80 100%
    );
    border-radius: 24px;
  }
  .odometer.odometer-auto-theme .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    padding: 0 2px;
    line-height: 120px;
    height: 120px;
    color: ${theme.color(Color.White)};
    font-size: 76px;
    font-weight: ${FontWeight.SemiBold};
  }
  .odometer.odometer-auto-theme .odometer-digit + .odometer-digit {
    margin-left: 0em;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner {
    left: 0.03em;
    width: 100%;
  }
`;
