import _ from "lodash";
import moment from "moment";

import { DateSetItem } from "./dateHelperTypes";

export const WEEKLY_INTERVAL_UNIT = "weekly";
export const MONTHLY_INTERVAL_UNIT = "monthly";
export const YEARLY_INTERVAL_UNIT = "yearly";
export const CUSTOM_INTERVAL_UNIT_OPTION = "custom";

export const INTERVAL_UNIT_OPTIONS: DateSetItem[] = [
  {
    value: WEEKLY_INTERVAL_UNIT,
    label: "interval_store.unit.weekly_interval_unit",
  },
  {
    value: MONTHLY_INTERVAL_UNIT,
    label: "interval_store.unit.monthly_interval_unit",
  },
  {
    value: YEARLY_INTERVAL_UNIT,
    label: "interval_store.unit.yearly_interval_unit",
  },
  {
    value: CUSTOM_INTERVAL_UNIT_OPTION,
    label: "interval_store.unit.custom_interval_unit",
  },
];

export const intervalUnitToMomentIntervalUnit = {
  weekly: "weeks",
  monthly: "months",
  yearly: "years",
};

export const UNTIL_FURTHER_NOTICE = "until_further_notice";
export const UNTIL_SPECIFIC_DATE = "until_a_specific_date";
export const UNTIL_SPECIFIC_COUNT = "for_a_specific_number_of_payments";

export const END_DATE_OPTIONS: DateSetItem[] = [
  {
    value: UNTIL_FURTHER_NOTICE,
    label: "interval_store.end_date.until_further_notice",
  },
  {
    value: UNTIL_SPECIFIC_COUNT,
    label: "interval_store.end_date.for_a_specific_number_of_payments",
  },
];

export const DATE_FORMAT = "YYYY-MM-DD";
export const LAST_WORKING_DAY = -1;

// For recuring monthly payments it's simpler to specify days 1-28 or the last
// day of the month rather than try to define the behaviour when a user wants
// payments to occur on the 29th and we then have to deal with February.
export const DAYS_OF_MONTH: Array<{ value: number; label: string }> = _.range(
  1,
  29
)
  .map((value) => ({
    value,
    label: moment.localeData().ordinal(value),
  }))
  .concat([
    {
      value: LAST_WORKING_DAY,
      label: "interval_store.days_of_month.last_day",
    },
  ]);

export const MONTHS_OF_YEAR: Array<{ value: number[]; label: string }> =
  _.range(0, 12).map((value) => ({
    value: [value],
    label: moment.months(value),
  }));

// moment works with % 7, which means that 1, 8 renders like Monday...Sunday
export const DAYS_OF_WEEK: DateSetItem[] = _.range(1, 8).map((value) => ({
  value: moment.weekdays(value).toLowerCase(),
  label: moment.weekdays(value),
}));

export const getMonthsFromStartDate = (
  isoStartDate: string,
  numberOfMonths = 13
): Array<{
  value: number[];
  label: string;
}> => {
  const startDate = moment(isoStartDate, DATE_FORMAT).utc();
  // add one to the value to include the current month i.e. range === [0...numberOfMonths+1]
  return _.range(numberOfMonths + 1).map((month) => {
    const date = startDate.clone().add(month, "months");
    return {
      value: [date.month(), date.year()],
      label: date.format("MMMM YYYY"),
    };
  });
};

export function buildStartDate(
  dayOfMonth: number,
  monthYear: [number, number]
): moment.Moment {
  const [month, year] = monthYear;
  const beginningOfDate = moment().utc().year(year).month(month);

  if (_.eq(dayOfMonth, LAST_WORKING_DAY)) return beginningOfDate.endOf("month");

  return beginningOfDate.date(dayOfMonth);
}
