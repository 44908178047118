import { useCustomerList } from "@gocardless/api/dashboard/customer";
import { usePaylinkList } from "@gocardless/api/dashboard/paylink";
import { usePlanList } from "@gocardless/api/dashboard/plan";
import { InstancesIncludeTotalCount } from "src/utils/types";

import { LastActivityType } from "../types";

interface useFetchLastActivityEntriesHookConfig {
  sizeOfList: number;
}

export const useFetchLastActivityEntries = ({
  sizeOfList,
}: useFetchLastActivityEntriesHookConfig) => {
  const { data: paylinkList, isLoading: isLoadingPaylinkList } = usePaylinkList(
    { limit: sizeOfList }
  );
  const { data: planList, isLoading: isLoadingPlanList } = usePlanList({
    limit: sizeOfList,
  });

  const { data: customerList, isLoading: isLoadingCustomerList } =
    useCustomerList({
      limit: sizeOfList,
      active_mandates: InstancesIncludeTotalCount.True,
    });

  return {
    entries: {
      [LastActivityType.TopOneOffPayments]: paylinkList,
      [LastActivityType.TopSubscriptionTemplates]: planList,
      [LastActivityType.RecentCustomers]: customerList,
    },
    isLoading:
      isLoadingCustomerList || isLoadingPlanList || isLoadingPaylinkList,
  };
};
