import { CSSRulesFunction } from "@gocardless/flux-react";

export const betaWidgetStyle: CSSRulesFunction = (theme) => [
  theme.responsive([0, theme.spacing(1)], (value) => ({
    borderBottomLeftRadius: value,
    borderBottomRightRadius: value,
  })),
  theme.responsive(["block", "flex"], (value) => ({
    display: value,
  })),
  theme.responsive([theme.spacing(2), theme.spacing(1.5)], (value) => ({
    paddingBottom: value,
  })),
];
