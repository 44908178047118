import { useEffect } from "react";

import { useReportingContext } from "../components/ReportingContextProvider";

export const useSetUpdatedAt = (updatedAt?: string) => {
  const { updatedAtDates, setUpdatedAtDates } = useReportingContext();

  useEffect(() => {
    if (updatedAt && !updatedAtDates.includes(updatedAt)) {
      setUpdatedAtDates((prevState) => [...prevState, updatedAt]);
    }
  }, [updatedAt, updatedAtDates, setUpdatedAtDates]);
};
