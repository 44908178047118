import { Trans } from "@lingui/macro";

import { OutboundPaymentBalanceType } from "./OutboundPaymentBalanceType";

interface OutboundPaymentBalanceCardTitleProps {
  type: OutboundPaymentBalanceType;
}

export const OutboundPaymentBalanceCardTitle: React.FC<
  OutboundPaymentBalanceCardTitleProps
> = ({ type }) => {
  switch (type) {
    case OutboundPaymentBalanceType.Current:
      return <Trans>Current balance</Trans>;
    case OutboundPaymentBalanceType.Available:
      return <Trans>Available funds</Trans>;
    case OutboundPaymentBalanceType.Scheduled:
      return <Trans>Scheduled payments</Trans>;
  }
};
