import { useEffect, useState } from "react";

export function useMediaQuery(mediaQuery: string): {
  mediaQueryList: MediaQueryList;
  loaded: boolean;
} {
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList | null>(
    null
  );

  useEffect(() => {
    if (!window) return;

    setMediaQueryList(window.matchMedia(mediaQuery));
  }, [mediaQuery]);

  if (!mediaQueryList) {
    return {
      mediaQueryList: {
        matches: false,
        media: "",
        onchange: () => {},
        addListener: () => {},
        removeListener: () => {},
        addEventListener: () => {},
        removeEventListener: () => {},
        dispatchEvent: () => true,
      },
      loaded: false,
    };
  }

  return { mediaQueryList, loaded: true };
}
