import { Box } from "@gocardless/flux-react";
import { TopBanners } from "src/components/routes/Home/components/TopBanners";

const items = [TopBanners];

export const ActionItems = () => (
  <Box>
    {items.map((Item, idx) => (
      <Item key={idx.toString()} />
    ))}
  </Box>
);
