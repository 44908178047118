import { LeaveFeedback } from "src/components/navigation/LeaveFeedback";
import { Route, RouteURLParams } from "src/common/routing";
import MFASvg from "src/assets/svg/suggested-actions-2fa.svg";
import LeaveFeedbackSvg from "src/assets/svg/suggested-actions-leave-feedback.svg";
import AddCustomersInBulkSVG from "src/assets/svg/suggested-actions-add-customers-in-bulk.svg";
import AddMoreCustomersSVG from "src/assets/svg/suggested-actions-add-more-customers.svg";
import SubscriptionTemplatesSVG from "src/assets/svg/suggested-actions-subscription-templates.svg";
import DDPaymentsSVG from "src/assets/svg/suggested-actions-dd-payment.svg";
import IBPPaymentsSVG from "src/assets/svg/suggested-actions-ibp-payment.svg";
import RetryFailedPaymentsSVG from "src/assets/svg/suggested-actions-dialog-retry-failed-payments.svg";
import InstalmentsSVG from "src/assets/svg/suggested-actions-instalments.svg";
import CreateMorePaymentsSVG from "src/assets/svg/suggested-actions-create-more-payments.svg";
import CalculatorSVG from "src/assets/svg/suggested-actions-calculator.svg";
import { useLocalStorage } from "react-use";
import { useSubscriptionList } from "@gocardless/api/dashboard/subscription";
import { useInstalmentScheduleList } from "@gocardless/api/dashboard/instalment-schedule";
import { PaymentStatusEnum } from "@gocardless/api/dashboard/types";
import { usePlanList } from "@gocardless/api/dashboard/plan";
import { usePaymentList } from "@gocardless/api/dashboard/payment";
import { useCustomerList } from "@gocardless/api/dashboard/customer";
import { getLocalTimeZone, today } from "@internationalized/date";
import {
  castStringToDate,
  parseToAbsoluteDateTimeString,
} from "src/common/date-helper";
import { usePaylinkList } from "@gocardless/api/dashboard/paylink";
import { Trans } from "@lingui/macro";
import { useCanIbpForDomesticCurrency } from "src/common/hooks/useCanIbp/useCanIbp";
import { useMemo } from "react";

import {
  useTwoFactorAuthenticationShowMessage,
  STORAGE_KEY as TWO_FACTOR_STORAGE_KEY,
} from "../../components/TwoFactorAuthenticationBanner";

import { ActionComponentProps, SuggestedActionType } from "./types";
import { TrySubscriptionTemplates } from "./dialogs/TrySubscriptionTemplates";
import { TryInstalments } from "./dialogs/TryInstalments";
import { RetryFailedPayments } from "./dialogs/RetryFailedPayments";
import { AddMoreCustomers } from "./dialogs/AddMoreCustomers";
import { AddCustomersInBulk } from "./dialogs/AddCustomersInBulk";
import { useCustomersAddedOneByOne } from "./hooks/useCustomersAddedOneByOne";
import { TryDDPayments } from "./dialogs/TryDDPayments";
import { TryIBPPayments } from "./dialogs/TryIBPPayments";
import { CreateMorePayments } from "./dialogs/CreateMorePayments";
import { useHasPaymentBeenCreatedWithinLastXDays } from "./hooks/useHasPaymentBeenCreatedWithinLastXDays";

const SUBSCRIPTIONS_COUNT_THRESHOLD = 3;
const PAYMENT_FAILED_THRESHOLD = 1;
const PAYMENT_SUBMITTED_THRESHOLD = 10;
const CUSTOMER_ADDED_ONE_BY_ONE_THRESHOLD = 5;
const PAYMENT_CREATED_THRESHOLD = 5;

interface SuggestedActionDataBaseType {
  id: SuggestedActionType;
  message: React.ReactNode;
  title: React.ReactNode;
  shouldShow?: boolean;
  imageComponent: string;
}

type SuggestedActionWithDialog = SuggestedActionDataBaseType & {
  ActionComponent: React.FC<ActionComponentProps>;
  link: undefined;
};

type SuggestedActionWithLink = SuggestedActionDataBaseType & {
  link: RouteURLParams | string;
  ActionComponent: undefined;
};

export type SuggestedActionProps =
  | SuggestedActionWithLink
  | SuggestedActionWithDialog;

const localTimeZone = getLocalTimeZone();
const localToday = today(localTimeZone);

export const useSuggestedActions = (): {
  data: SuggestedActionProps[];
  isLoading: boolean;
} => {
  const { canIBP } = useCanIbpForDomesticCurrency();
  const { data: subscriptionListResponse, isLoading: loadingSubscriptions } =
    useSubscriptionList();
  const { data: planListResponse, isLoading: loadingPlans } = usePlanList();

  const { data: iBPcreatedResponse, isLoading: loadingIBP } = usePaylinkList(
    {
      instant_bank_pay: true,
    },
    !!canIBP
  );

  const {
    paymentExists: hasPaymentBeenCreatedWithLastXDays,
    isLoading: loadingPaymentExist,
  } = useHasPaymentBeenCreatedWithinLastXDays({
    daySpan: PAYMENT_CREATED_THRESHOLD,
  });

  const { data: dDcreatedResponse, isLoading: loadingDD } = usePaylinkList({
    instant_bank_pay: false,
  });

  const { data: customerListResponse, isLoading: loadingCustomers } =
    useCustomerList({
      created_at: {
        gte: castStringToDate(
          parseToAbsoluteDateTimeString(localToday.subtract({ days: 10 }))
        ),
      },
    });
  const { isLoading: loadingCustomersOneByOne, customerAddedOneByOne } =
    useCustomersAddedOneByOne({ daySpan: 10 });
  const { data: instalmentListResponse, isLoading: loadingInstalments } =
    useInstalmentScheduleList({});
  const { data: failedPaymentsResponse, isLoading: loadingFailedPayments } =
    usePaymentList({
      status: PaymentStatusEnum.Failed,
    });
  const {
    data: paymentSubmittedListResponse,
    isLoading: loadingPaymentSubmitted,
  } = usePaymentList({
    status: PaymentStatusEnum.Submitted,
  });

  const [storageItem] = useLocalStorage(TWO_FACTOR_STORAGE_KEY, "");
  const show2FABanner = useTwoFactorAuthenticationShowMessage({
    isBannerDismissedUntilDateString: storageItem,
  });

  const isLoading = useMemo(
    () =>
      loadingSubscriptions ||
      loadingPlans ||
      loadingIBP ||
      loadingDD ||
      loadingCustomers ||
      loadingCustomersOneByOne ||
      loadingInstalments ||
      loadingFailedPayments ||
      loadingPaymentSubmitted,
    [
      loadingCustomers,
      loadingCustomersOneByOne,
      loadingDD,
      loadingFailedPayments,
      loadingIBP,
      loadingInstalments,
      loadingPaymentSubmitted,
      loadingPlans,
      loadingSubscriptions,
    ]
  );
  const showSubscriptionTemplates =
    (subscriptionListResponse?.subscriptions?.length ?? 0) >=
      SUBSCRIPTIONS_COUNT_THRESHOLD && planListResponse?.plans?.length === 0;

  const showTryDDPayments =
    dDcreatedResponse && dDcreatedResponse.paylinks?.length === 0;
  const showIBPPayments =
    iBPcreatedResponse && iBPcreatedResponse.paylinks?.length === 0;
  const showInstalmentListResponse =
    instalmentListResponse &&
    instalmentListResponse?.instalment_schedules?.length === 0;

  const showRetryFailedPayments =
    failedPaymentsResponse &&
    (failedPaymentsResponse?.payments?.length ?? 0) >= PAYMENT_FAILED_THRESHOLD;

  const showCustomerAddCustomerInBulk =
    (customerAddedOneByOne ?? 0) >= CUSTOMER_ADDED_ONE_BY_ONE_THRESHOLD;
  const showAddMoreCustomers = customerListResponse?.customers?.length === 0;

  const showCreateMorePayments =
    !loadingPaymentExist && !hasPaymentBeenCreatedWithLastXDays;
  const showSwitchCustomerBenefit =
    (paymentSubmittedListResponse?.payments?.length ?? 0) <=
    PAYMENT_SUBMITTED_THRESHOLD;

  const showShareFeedback = useMemo(
    () =>
      !show2FABanner &&
      !showSubscriptionTemplates &&
      !showTryDDPayments &&
      !showIBPPayments &&
      !showCreateMorePayments &&
      !showCustomerAddCustomerInBulk &&
      !showAddMoreCustomers &&
      !showRetryFailedPayments &&
      !showInstalmentListResponse &&
      !showSwitchCustomerBenefit,
    [
      show2FABanner,
      showAddMoreCustomers,
      showCreateMorePayments,
      showCustomerAddCustomerInBulk,
      showIBPPayments,
      showInstalmentListResponse,
      showRetryFailedPayments,
      showSubscriptionTemplates,
      showSwitchCustomerBenefit,
      showTryDDPayments,
    ]
  );
  const data = [
    {
      id: SuggestedActionType.LeaveFeedback,
      message: (
        <Trans>
          Tell us about your experience to help us make GoCardless better
        </Trans>
      ),
      title: <Trans>Share your feedback</Trans>,
      shouldShow: showShareFeedback,
      imageComponent: LeaveFeedbackSvg,
      ActionComponent: LeaveFeedback,
      link: undefined,
    },
    {
      id: SuggestedActionType.TwoFactorAuth,
      message: <Trans>Add extra security to your account</Trans>,
      title: <Trans>Enable two-factor authentication</Trans>,
      shouldShow: show2FABanner,
      imageComponent: MFASvg,
      ActionComponent: undefined,
      link: { route: Route.MFASetup },
    },
    {
      id: SuggestedActionType.TrySubscriptionTemplates,
      message: (
        <Trans>
          Save time on admin and manage multiple subscriptions at once
        </Trans>
      ),
      title: <Trans>Try Subscription templates</Trans>,
      shouldShow: showSubscriptionTemplates,
      imageComponent: SubscriptionTemplatesSVG,
      ActionComponent: TrySubscriptionTemplates,
      link: undefined,
    },
    {
      id: SuggestedActionType.TryDDPayments,
      title: <Trans>Try One-off payments with Direct Debit</Trans>,
      message: (
        <Trans>Schedule payments for one-off charges and invoices</Trans>
      ),
      shouldShow: showTryDDPayments,
      imageComponent: DDPaymentsSVG,
      ActionComponent: TryDDPayments,
      link: undefined,
    },
    {
      id: SuggestedActionType.TryIBPPayments,
      title: <Trans>Try Instant payments</Trans>,
      message: (
        <Trans>
          Get instant confirmation of one-off charges for products and services
        </Trans>
      ),
      shouldShow: showIBPPayments,
      imageComponent: IBPPaymentsSVG,
      ActionComponent: TryIBPPayments,
      link: undefined,
    },
    {
      id: SuggestedActionType.TryInstalments,
      title: <Trans>Try Instalments</Trans>,
      message: (
        <Trans>
          Give your customers more flexibility and split the cost of larger
          payments
        </Trans>
      ),
      shouldShow: showInstalmentListResponse,
      imageComponent: InstalmentsSVG,
      ActionComponent: TryInstalments,
      link: undefined,
    },
    {
      id: SuggestedActionType.RetryFailedPayments,
      title: <Trans>Retry your failed payments</Trans>,
      message: <Trans>Go to your failed payments and retry them</Trans>,
      shouldShow: showRetryFailedPayments,
      imageComponent: RetryFailedPaymentsSVG,
      ActionComponent: RetryFailedPayments,
      link: undefined,
    },
    {
      id: SuggestedActionType.AddCustomersInBulk,
      title: <Trans>Add multiple new customers at once</Trans>,
      message: <Trans>Upload a CSV of customers to invite them in bulk</Trans>,
      shouldShow: showCustomerAddCustomerInBulk,
      imageComponent: AddCustomersInBulkSVG,
      ActionComponent: AddCustomersInBulk,
      link: undefined,
    },
    {
      id: SuggestedActionType.AddMoreCustomers,
      title: <Trans>Add more customers</Trans>,
      message: (
        <Trans>{`Here's a reminder of how to invite more customers to GoCardless`}</Trans>
      ),
      shouldShow: showAddMoreCustomers,
      imageComponent: AddMoreCustomersSVG,
      ActionComponent: AddMoreCustomers,
      link: undefined,
    },
    {
      id: SuggestedActionType.CreateMorePayments,
      title: <Trans>Create more payments</Trans>,
      message: (
        <Trans>{`Here's a reminder of the different ways you can charge customers`}</Trans>
      ),
      shouldShow: showCreateMorePayments,
      imageComponent: CreateMorePaymentsSVG,
      ActionComponent: CreateMorePayments,
      link: undefined,
    },
    {
      id: SuggestedActionType.SwitchCustomersBenefits,
      title: (
        <Trans>
          Could you benefit from switching more customers to GoCardless?
        </Trans>
      ),
      message: <Trans>Try the calculator</Trans>,
      shouldShow: showSwitchCustomerBenefit,
      imageComponent: CalculatorSVG,
      ActionComponent: undefined,
      link: "https://content.gocardless.com/add-customer-benefits/benefits-switching",
    },
  ];

  return {
    data,
    isLoading,
  };
};
