import { Currency } from "src/common/currencies";
import { useOrganisation } from "src/queries/organisation";

export const useCollectionCurrencies = () => {
  const {
    available_currencies: availableCurrencies,
    domestic_currency: domesticCurrency,
    fx_payout_currency: fxPayoutCurrency,
  } = useOrganisation() ?? {};

  const collectionCurrencies = [
    ...new Set([
      domesticCurrency ?? Currency.Gbp,
      fxPayoutCurrency,
      ...(availableCurrencies ?? []),
    ]),
  ].filter(Boolean) as Currency[];

  return {
    collectionCurrencies,
  };
};
